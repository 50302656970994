import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Skeleton,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

import { RowProps } from './Row.type';

const Row = <ItemType extends unknown>({
  children,
  data,
  fetchingItems,
  handleClick,
  handleRowClick,
  index,
  isExpandable,
  isFetching,
  isSelected,
  itemList,
  labelIdPrefix,
  preFetch,
  selectId,
  skeletonRowHeight,
}: RowProps<ItemType>) => {
  const [detailOpen, setDetailOpen] = useState(false);
  const isItemSelected =
    isSelected && selectId ? isSelected(selectId) : undefined;

  const labelId = `${labelIdPrefix}-table-checkbox-${index}`;

  useEffect(() => {
    if (!isExpandable) setDetailOpen(false);
  }, [isExpandable]);

  return (
    <>
      {isFetching && fetchingItems?.includes(selectId ?? '') ? (
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" disabled size="small">
              <KeyboardArrowDownIcon />
            </IconButton>
          </TableCell>
          {isItemSelected !== undefined && selectId ? (
            <TableCell padding="checkbox">
              <Checkbox disabled inputProps={{ 'aria-labelledby': labelId }} />
            </TableCell>
          ) : null}
          {itemList.map((_, index) => (
            <TableCell key={index}>
              <Skeleton
                animation="wave"
                height={skeletonRowHeight ? skeletonRowHeight - 32 : 40}
                variant="text"
              />
            </TableCell>
          ))}
        </TableRow>
      ) : (
        <>
          <TableRow
            aria-checked={isItemSelected}
            hover
            onClick={() => {
              if (isExpandable) {
                if (handleRowClick === undefined) {
                  setDetailOpen(!detailOpen);
                } else if (data) {
                  handleRowClick(data);
                }
              }
            }}
            onMouseEnter={preFetch && data ? () => preFetch(data) : undefined}
            role="checkbox"
            selected={isItemSelected}
            tabIndex={-1}
            sx={{
              cursor: handleRowClick === undefined ? 'default' : 'pointer',
            }}
          >
            <TableCell>
              <IconButton
                aria-label="expand row"
                disabled={!isExpandable}
                onClick={(event) => {
                  event.stopPropagation();
                  if (isExpandable) setDetailOpen(!detailOpen);
                }}
                size="small"
              >
                {detailOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            {isItemSelected !== undefined && selectId ? (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event, selectId);
                  }}
                />
              </TableCell>
            ) : null}
            {itemList.map((item, index) => (
              <TableCell key={index} align="left">
                {item}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell
              style={{ width: '100%', paddingBottom: 0, paddingTop: 0 }}
              colSpan={8}
            >
              <Collapse in={detailOpen} timeout="auto" unmountOnExit>
                <Box margin={2}>{children}</Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

export default Row;
