import { ReactNode } from 'react';
import { Outlet, Link } from 'react-router-dom';

import Logo from '@components/atoms/Icons/Logo';
import GuestGuard from '@components/templates/GuestGuard';

import { BrandWrapper, Wrapper, StyledPaper } from './AuthLayout.style';

const AuthLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <GuestGuard>
      <Wrapper>
        <BrandWrapper>
          <Link to="/">
            <Logo />
          </Link>
        </BrandWrapper>
        <StyledPaper>
          {children}
          <Outlet />
        </StyledPaper>
      </Wrapper>
    </GuestGuard>
  );
};

export default AuthLayout;
