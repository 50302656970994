import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Avatar,
  CardActionArea,
  CardMedia,
  Grid,
  IconButton,
  Popover,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import FACEBOOK_LOGO from '@assets/facebook.jpg';
import TWITCH_LOGO from '@assets/twitch.jpg';
import YOUTUBE_LOGO from '@assets/youtube.jpeg';
import SOOP_LOGO from '@assets/soop.webp';
import {
  Facebook,
  Live,
  RTMP,
  RTSP,
  SRT,
  Twitch,
  Youtube,
} from '@components/atoms/Icons';
import TableList from '@components/molecules/TableList';
import { ServerNameProps, Detail } from '@usertypes/broadcast';
import { dayformatter } from '@utils/utilFunctions';

import {
  AvatarArea,
  Card,
  CardContent,
  Overlay,
  SubTitle,
  TextWrapper,
  Title,
  TitleWrapper,
} from './GridItem.style';
import { GridItemProps } from './GridItem.type';

const GridItem = ({
  anchorEl,
  broadcast,
  clickedId,
  handleClick,
  handleClose,
}: GridItemProps) => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const SERVER_NAME: ServerNameProps = {
    ALL: 'all',
    FACEBOOK: 'Facebook',
    RTMP: 'rtmp',
    RTSP: 'rtsp',
    SRT: 'srt',
    TWITCH: 'Twitch',
    YOUTUBE: 'Youtube',
    SOOP: 'Soop',
  };

  const Images = {
    [SERVER_NAME.YOUTUBE]: YOUTUBE_LOGO,
    [SERVER_NAME.TWITCH]: TWITCH_LOGO,
    [SERVER_NAME.FACEBOOK]: FACEBOOK_LOGO,
    [SERVER_NAME.SOOP]: SOOP_LOGO,
  };
  const logo = Images[broadcast.serverName];

  const image =
    broadcast.serverName === 'Youtube'
      ? `http://img.youtube.com/vi/${broadcast.videoId}/0.jpg`
      : broadcast.thumbnailUrl || logo || undefined;

  const Icon = () => {
    switch (broadcast.serverName) {
      case 'Facebook':
        return <Facebook />;
      case 'rtmp':
        return <RTMP />;
      case 'rtsp':
        return <RTSP />;
      case 'srt':
        return <SRT />;
      case 'Twitch':
        return <Twitch />;
      case 'Youtube':
        return <Youtube />;
      case 'Soop':
        return <Avatar src={SOOP_LOGO} alt={broadcast.serverName} />;
      default:
        return <Avatar alt={broadcast.serverName} />;
    }
  };

  const open = Boolean(anchorEl) && clickedId === broadcast.id;
  const id = open ? 'simple-popover' : undefined;

  const createAt = new Date(broadcast.createdAt);
  const endedAt = broadcast.endedAt ? new Date(broadcast.endedAt) : null;
  const runningTimes = endedAt ? endedAt.getTime() - createAt.getTime() : 0;

  const detail: Detail = {
    title: broadcast.title,
    platform: broadcast.serverName,
    userName: broadcast.userName,
    visibility: broadcast.status === 'end' ? 'VOD' : broadcast.status,
    time: endedAt
      ? `${dayformatter(createAt, 'lll')} ~ ${dayformatter(endedAt, 'lll')} `
      : `${dayformatter(createAt, 'lll')} ~`,
    topView: broadcast.topView,
    avgView: broadcast.avgView,
    resolution: broadcast.resolution,
    bitrate: broadcast.bitrate,
    information: `${broadcast.videoId}, ...etc`,
  };

  const navigateHandler = () =>
    navigate(`./${broadcast.id}`, {
      state: {
        broadcast,
      },
    });

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      xl={3}
      sx={{ backgroundColor: 'background.default' }}
    >
      <Card>
        <CardActionArea onClick={navigateHandler}>
          <CardMedia
            alt={broadcast.title}
            component="img"
            image={image}
            onError={(e: any) => {
              e.target.src = logo;
            }}
            sx={{
              objectFit: 'contain',
              height: isDownMd ? '50vw' : '220px',
              backgroundColor: (theme) => theme.palette.background.default,
            }}
          />
          {endedAt ? (
            <Overlay>
              {dayjs
                .utc(runningTimes)
                .format(runningTimes >= 60 * 60 * 1000 ? 'H:mm:ss' : 'm:ss')}
            </Overlay>
          ) : (
            <Overlay isLive>
              <Live /> <span>LIVE</span>
            </Overlay>
          )}
        </CardActionArea>
        <CardContent>
          <TitleWrapper>
            <AvatarArea>
              <Icon />
            </AvatarArea>
            <TextWrapper>
              <Title gutterBottom variant="h6" onClick={navigateHandler}>
                {broadcast.title}
              </Title>
              <SubTitle noWrap variant="subtitle1">
                {broadcast.userName}
              </SubTitle>
              <SubTitle noWrap variant="subtitle1">
                {Intl.NumberFormat('en', { notation: 'compact' }).format(
                  broadcast.avgView,
                )}{' '}
                views · {dayjs(broadcast.createdAt).fromNow()}
              </SubTitle>
            </TextWrapper>
            <IconButton
              aria-describedby={id}
              aria-label="more"
              onClick={(e) => handleClick(e, broadcast.id)}
              sx={{ height: '40px', width: '40px' }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              id={id}
              onClose={() => handleClose()}
              open={open}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              {open && <TableList detail={detail} />}
            </Popover>
          </TitleWrapper>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default GridItem;
