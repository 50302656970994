import { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import useScript from '@hooks/useScript';

import { firebaseConfig } from '../../config';

const PlaceField = ({
  setFormPlaceData,
  defaultAddress,
}: {
  setFormPlaceData: ({
    place,
    placeId,
    detailAddress,
  }: {
    place?: string;
    placeId?: string;
    detailAddress?: string;
  }) => void;
  defaultAddress?: string;
}) => {
  const { loading } = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${firebaseConfig.apiKey}&libraries=places&callback=Function.prototype`,
  );

  const [isGoogleMapLoading, setIsGoogleMapLoading] = useState(true);
  const [place, setPlace] = useState<string | null>('');
  const [placeList, setPlaceList] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  useEffect(() => {
    if (!loading) {
      setIsGoogleMapLoading(Boolean(!Object.hasOwn(window, 'google')));
    }
  }, [loading]);

  useEffect(() => {
    const setDefaultPlace = async () => {
      try {
        if (defaultAddress) {
          const { predictions } =
            await new window.google.maps.places.AutocompleteService().getPlacePredictions(
              {
                input: defaultAddress,
                language: 'ko',
              },
            );

          if (predictions.length > 0) {
            setPlace(predictions[0].structured_formatting.main_text);
            setFormPlaceData({
              place: predictions[0].structured_formatting.main_text,
              placeId: predictions[0].place_id,
            });
          } else {
            // 크롤링을 통해 추출된 주소 데이터로 주소 검색이 되지 않는 경우 상세 주소에 저장
            setFormPlaceData({ detailAddress: defaultAddress });
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    setDefaultPlace();
  }, [defaultAddress, setFormPlaceData]);

  return (
    <Box sx={{ mb: 2.5, width: '100%' }}>
      <Autocomplete
        freeSolo
        clearOnBlur
        loading={isGoogleMapLoading}
        value={place}
        options={placeList}
        getOptionLabel={(place) => {
          if (typeof place === 'string') return place;
          else return place.structured_formatting.main_text;
        }}
        onChange={(_, inputValue) => {
          if (inputValue && typeof inputValue === 'object') {
            setPlace(inputValue.structured_formatting.main_text);
            setFormPlaceData({
              place: inputValue.structured_formatting.main_text,
              placeId: inputValue.place_id,
            });
          }
        }}
        onInputChange={
          isGoogleMapLoading
            ? undefined
            : (_, inputValue: string) => {
                new window.google.maps.places.AutocompleteService().getPlacePredictions(
                  {
                    input: inputValue,
                    language: 'ko',
                  },
                  (result) => {
                    if (result) setPlaceList(result);
                    else setPlaceList([]);
                  },
                );
              }
        }
        renderInput={(params) => (
          <TextField
            variant="outlined"
            color="info"
            sx={{
              height: '50px',
            }}
            label="장소"
            {...params}
          />
        )}
      />
    </Box>
  );
};

export default PlaceField;
