import {
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  styled,
} from '@mui/material';

export const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.headbar};
`;

export const IconButton = styled(MuiIconButton)`
  height: 100%;
  svg {
    fill: ${({ theme }) => theme.palette.text.bold};
    height: 22px;
    width: 22px;
  }
`;
