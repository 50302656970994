import { styled } from '@mui/material';
import { Paper } from '@components/atoms/Spacing';

const drawerWidth = 258;

export const Root = styled('div')`
  display: flex;
  min-height: 100vh;
  width: 100%;
`;

export const Drawer = styled('div')`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

export const AppContent = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media (min-width: 900px) {
    width: calc(100% - 258px);
    max-width: calc(100% - 258px);
  }
`;

export const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
