import { useEffect, useState } from 'react';

const useScript = (src: string) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const prevScript = document.querySelector(`script[src="${src}"]`);

    if (!prevScript) {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      const handleLoad = () => setLoading(false);

      script.addEventListener('load', handleLoad);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', handleLoad);
      };
    }
  }, [src]);

  return { loading };
};

export default useScript;
