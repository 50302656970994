import {
  Broadcast,
  BroadcastsStatistics,
  FilterType,
  OsType,
  ServerNameType,
  TimestampType,
} from '@usertypes/broadcast';
import { fetchLiveJSON } from '@utils/api-helpers';

export const getBroadcastsListRequest = (
  token: string,
  limit: number,
  order: 'avgViews' | 'createdAt',
  page: number,
  serverName: ServerNameType,
  status: string,
  timestamp: TimestampType,
  location: string,
  language: string,
  os: OsType,
) => {
  let getReqUrl = '/api/alpha/broadcasts/?';
  if (page || page === 0) {
    getReqUrl = getReqUrl + 'page=' + page;
  }
  if (order === 'createdAt') {
    getReqUrl = getReqUrl + '&createdAtOrder=DESC';
  }
  if (limit) {
    getReqUrl = getReqUrl + '&limit=' + limit;
  }
  if (status) {
    getReqUrl = getReqUrl + '&status=' + status;
  }
  if (serverName && serverName !== 'all') {
    getReqUrl = getReqUrl + '&serverName=' + serverName;
  }
  if (location && location !== 'all') {
    getReqUrl = getReqUrl + '&location=' + location;
  }
  if (language && language !== 'all') {
    getReqUrl = getReqUrl + '&language=' + language;
  }
  if (os && os !== 'all') {
    getReqUrl = getReqUrl + '&os=' + os;
  }

  if (timestamp && timestamp !== 'all') {
    let date = new Date();
    switch (timestamp) {
      case 'hour':
        date = new Date(date.getTime() - 1000 * 60 * 60);
        break;
      case 'day':
        date = new Date(date.getTime() - 1000 * 60 * 60 * 24);
        break;
      case 'week':
        date = new Date(date.getTime() - 1000 * 60 * 60 * 24 * 7);
        break;
      case 'month':
        date = new Date(date.getTime() - 1000 * 60 * 60 * 24 * 31);
        break;
      case 'year':
        date = new Date(date.getTime() - 1000 * 60 * 60 * 24 * 365);
        break;
      default:
        break;
    }
    getReqUrl = getReqUrl + '&timestamp=' + date.getTime();
  }

  return fetchLiveJSON('GET', token, getReqUrl) as Promise<{
    broadcasts: Broadcast[];
    next: number;
    pageCount: number;
    prev?: number;
    totalCount: number;
  }>;
};

export const getBroadcastRequestById = (token: string, id: string) =>
  fetchLiveJSON(
    'GET',
    token,
    `/api/alpha/broadcasts/${id}`,
  ) as Promise<Broadcast>;

export const getBroadcastsStatistics = (token: string) =>
  fetchLiveJSON(
    'GET',
    token,
    `/api/alpha/broadcasts/statistics?timeRange=day`,
  ) as Promise<BroadcastsStatistics>;

export const getBroadcastCount = (
  token: string,
  from: string,
  to: string,
  filter: FilterType,
) =>
  fetchLiveJSON(
    'GET',
    token,
    `/api/alpha/broadcasts/statistics/count?from=${from}&to=${to}
		${filter.location !== 'all' ? `&location=${filter.location}` : ''}
		${filter.serverName !== 'all' ? `&serverName=${filter.serverName}` : ''}`,
  );

export const getBroadcastView = (
  token: string,
  from: string,
  to: string,
  filter: FilterType,
) =>
  fetchLiveJSON(
    'GET',
    token,
    `/api/alpha/broadcasts/statistics/views?from=${from}&to=${to}	${
      filter.location !== 'all' ? `&location=${filter.location}` : ''
    }
		${filter.serverName !== 'all' ? `&serverName=${filter.serverName}` : ''}`,
  );

export const getBroadcastTime = (
  token: string,
  from: string,
  to: string,
  filter: FilterType,
) =>
  fetchLiveJSON(
    'GET',
    token,
    `/api/alpha/broadcasts/statistics/time?from=${from}&to=${to}	${
      filter.location !== 'all' ? `&location=${filter.location}` : ''
    }
		${filter.serverName !== 'all' ? `&serverName=${filter.serverName}` : ''}`,
  );
