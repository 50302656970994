import { Grid } from '@mui/material';
import { Skeleton, useTheme, useMediaQuery } from '@mui/material';

import {
  AvatarArea,
  Card,
  CardContent,
  SubTitle,
  TextWrapper,
  Title,
  TitleWrapper,
} from './GridItem.style';

const GridSkeleton = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      xl={3}
      sx={{ backgroundColor: 'background.default' }}
    >
      <Card>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={isDownMd ? '50vw' : '220px'}
        />
        <CardContent>
          <TitleWrapper>
            <AvatarArea>
              <Skeleton
                animation="wave"
                variant="circular"
                width="100%"
                height="100%"
              />
            </AvatarArea>
            <TextWrapper>
              <Title gutterBottom variant="h6">
                <Skeleton width="80%" />
              </Title>
              <SubTitle noWrap variant="subtitle1">
                <Skeleton width="60%" />
              </SubTitle>
              <SubTitle noWrap variant="subtitle1">
                <Skeleton width="60%" />
              </SubTitle>
            </TextWrapper>
          </TitleWrapper>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default GridSkeleton;
