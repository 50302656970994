import {
  FormControl,
  InputLabel,
  SelectProps,
  Select as MuiSelect,
} from '@mui/material';
import { ReactNode } from 'react';
import {
  useController,
  FieldValues,
  FieldPath,
  UseControllerProps,
} from 'react-hook-form';

interface MuiProps {
  selectProps?: SelectProps;
  children?: ReactNode;
}

const Select = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  selectProps,
  children,
  ...props
}: MuiProps & UseControllerProps<TFieldValues, TName>) => {
  const { field } = useController({ ...props });

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel>{selectProps?.label}</InputLabel>
      <MuiSelect {...field} {...selectProps}>
        {children}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
