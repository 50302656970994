import { Switch, SwitchProps } from '@mui/material';
import { grey } from '@mui/material/colors';

interface CustomToggleProps extends SwitchProps {
  id: string;
}

const Toggle = ({ id, size, sx, ...props }: CustomToggleProps) => (
  <Switch
    id={id}
    size={size}
    sx={(theme) => ({
      display: 'flex',
      padding: 0,
      height: size === 'small' ? 20 : 30,
      width: size === 'small' ? 40 : 60,
      '.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
      },
      '.MuiSwitch-thumb': {
        borderRadius: '100px',
        height: size === 'small' ? 16 : 24,
        width: size === 'small' ? 16 : 24,
      },
      '.MuiSwitch-track': {
        backgroundColor: grey[400],
        borderRadius: '100px',
        boxSizing: 'border-box',
        opacity: 1,
      },
      '.MuiSwitch-switchBase': {
        padding: size === 'small' ? '2px' : '3px',
      },
      '.MuiSwitch-switchBase.Mui-checked': {
        transform: size === 'small' ? 'translateX(20px)' : 'translateX(30px)',
      },
    })}
    {...props}
  />
);

export default Toggle;
