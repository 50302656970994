import type { ReactNode } from 'react';
import {
  Box,
  IconButton,
  Toolbar as TableToolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import Toggle from '@components/atoms/Toggle';

import { RefreshIcon } from './Toolbar.style';

const Toolbar = ({
  children,
  dense,
  handleChangeDense,
  handleRefresh,
  header,
  isFetching,
  selected,
}: {
  children?: ReactNode;
  dense: boolean;
  handleChangeDense: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRefresh?: () => void;
  header: string;
  isFetching: boolean;
  selected: readonly string[];
}) => (
  <TableToolbar
    sx={{
      pl: { sm: 2 },
      pr: { xs: 1, sm: 1 },
    }}
  >
    {selected.length > 0 ? (
      <Typography
        color="inherit"
        component="div"
        sx={{ flex: '1 1 30%' }}
        variant="subtitle1"
      >
        {selected.length} selected
      </Typography>
    ) : (
      <Typography
        component="div"
        id="tableTitle"
        sx={{ flex: '1 1 30%' }}
        variant="h6"
      >
        {header}
      </Typography>
    )}
    <label htmlFor="densePadding">Dense padding</label>
    <Box sx={{ margin: '0 20px' }}>
      <Toggle
        id="densePadding"
        onChange={handleChangeDense}
        size="small"
        checked={dense}
      />
    </Box>
    {children}
    {handleRefresh ? (
      <Tooltip title="Refresh list">
        <IconButton aria-label="Refresh list" onClick={handleRefresh}>
          <RefreshIcon isFetching={isFetching} />
        </IconButton>
      </Tooltip>
    ) : null}
  </TableToolbar>
);

export default Toolbar;
