import { useContext } from 'react';

import { ShoppingEventQueryContext } from '@contexts/ShoppingEventQueryContext';

const useShoppingEventQuery = () => {
  const context = useContext(ShoppingEventQueryContext);

  if (!context)
    throw new Error('MatchContext must be placed within MatchProvider');

  return context;
};

export default useShoppingEventQuery;
