import { fetchLiveJSON } from '@utils/api-helpers';
import {
  EventList,
  EventListParams,
  EventsType,
  ShopifyShopDetail,
  ShopifyShopList,
  ShopifyShopPlanInfo,
  ShopifyShopPlanUpdateProps,
  ShopifyShopQuota,
  ShopifyShopQuotaUpdateProps,
  ShopifyShopUpdateProps,
} from '@usertypes/shopify';

export const getShopifyShopsList = async ({
  token,
  page,
  sort,
  createAtFilterStart,
  createAtFilterEnd,
  limit,
}: {
  token: string;
  page: number;
  sort: 'createAtOrder' | 'totalBillingOrder';
  createAtFilterStart?: string;
  createAtFilterEnd?: string;
  disabledFilter?: boolean;
  limit: string;
}): Promise<ShopifyShopList> => {
  const params = new URLSearchParams({
    page: page.toString(),
    limit,
  });
  if (createAtFilterStart) {
    params.append('createAtFilterStart', createAtFilterStart);
  }
  if (createAtFilterEnd) {
    params.append('createAtFilterEnd', createAtFilterEnd);
  }
  params.append(sort, 'DESC');

  return fetchLiveJSON(
    'GET',
    token,
    `/admin/v1/shopify/shops?${params.toString()}`,
  );
};

export const getShopifyEventsList = async ({
  token,
  filters,
}: {
  token: string;
  filters: EventListParams;
}) => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([filterName, filterValue]) => {
    if (filterValue && filterValue !== '') {
      params.append(filterName, filterValue);
    }
  });

  const apiUrl = `/admin/v1/shopify/events?${params.toString()}`;
  return fetchLiveJSON('GET', token, apiUrl) as Promise<EventList>;
};

export const getShopifyShopDetails = (
  token: string,
  shopName: string,
): Promise<ShopifyShopDetail> =>
  fetchLiveJSON('GET', token, `/admin/v1/shopify/shops/${shopName}`);

export const getShopifyShopPlanInfo = (
  token: string,
  shopName: string,
): Promise<ShopifyShopPlanInfo> =>
  fetchLiveJSON(
    'GET',
    token,
    `/admin/v1/shopify/shops/${shopName}/configuration`,
  );

export const updateShopifyShop = (
  token: string,
  shopId: string,
  data: ShopifyShopUpdateProps,
) => fetchLiveJSON('PATCH', token, `/admin/v1/shopify/shops/${shopId}`, data);

export const updateShopifyShopPlan = (
  token: string,
  shopName: string,
  data: ShopifyShopPlanUpdateProps,
) =>
  fetchLiveJSON(
    'PATCH',
    token,
    `/admin/v1/shopify/shops/${shopName}/configuration`,
    data,
  );

export const getShopifyShopEvents = (token: string, shopId: string) =>
  fetchLiveJSON(
    'GET',
    token,
    `/admin/v1/shopify/shops/${shopId}/events`,
  ) as Promise<EventsType>;

export const getShopifyShopQuota = (token: string, shopName: string) =>
  fetchLiveJSON(
    'GET',
    token,
    `/admin/v1/shopify/shops/${shopName}/quota`,
  ) as Promise<ShopifyShopQuota>;

export const updateShopifyShopQuota = (
  token: string,
  shopName: string,
  data: ShopifyShopQuotaUpdateProps,
) =>
  fetchLiveJSON(
    'PATCH',
    token,
    `/admin/v1/shopify/shops/${shopName}/quota`,
    data,
  ) as Promise<ShopifyShopQuotaUpdateProps>;
