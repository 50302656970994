import {
  Bell,
  Cpu,
  CreditCard,
  Dribbble,
  HelpCircle,
  Layout,
  ShoppingBag,
  ShoppingCart,
  User,
  FilePlus,
  Video,
} from 'react-feather';

import { SidebarItemsType } from '@usertypes/sidebar';

const camerafiSection = [
  {
    href: '/dashboard',
    icon: Layout,
    title: 'Dashboard',
  },
  {
    href: '/account',
    icon: User,
    title: 'Account',
    children: [
      {
        href: '/account/user',
        title: 'User',
      },
      {
        href: '/account/blocked',
        title: 'Blocked',
      },
      {
        href: '/account/admin',
        title: 'Admin',
      },
    ],
  },
  {
    href: '/notification',
    icon: Bell,
    title: 'Notification',
  },
] as SidebarItemsType[];

const camerafiLiveSection = [
  {
    href: '/broadcast',
    icon: Video,
    title: 'Broadcast',
    children: [
      {
        href: '/broadcast/live',
        title: 'Live',
      },
      {
        href: '/broadcast/vod',
        title: 'Vod',
      },
    ],
  },
  {
    href: '/help-center',
    icon: HelpCircle,
    title: 'HelpCenter',
    children: [
      {
        href: '/help-center/user-guide',
        title: 'UserGuide',
      },
      {
        href: '/help-center/notice',
        title: 'Notice',
      },
      {
        href: '/help-center/faq',
        title: 'FAQ',
      },
      {
        href: '/help-center/new-manual',
        title: 'NewManual',
      },
    ],
  },
] as SidebarItemsType[];

const studioSection = [
  {
    href: '/match',
    icon: Dribbble,
    title: 'Match',
  },
  {
    href: '/create-event',
    icon: FilePlus,
    title: 'Create Event',
  },
  {
    href: '/coupon',
    icon: CreditCard,
    title: 'Coupon',
    children: [
      {
        href: '/coupon/list',
        title: 'List',
      },
      {
        href: '/coupon/create',
        title: 'Create',
      },
    ],
  },
] as SidebarItemsType[];

const camerafiShoppingSection = [
  {
    href: '/camerafiShopping/shops',
    icon: ShoppingBag,
    title: 'Shops',
  },
  {
    href: '/camerafiShopping/events',
    icon: ShoppingCart,
    title: 'Events',
  },
] as SidebarItemsType[];

const ai = [
  {
    href: '/Q&A',
    icon: Cpu,
    title: 'Q&A',
    children: [
      {
        href: '/Q&A/playStore',
        title: 'PlayStore',
      },
      {
        href: '/Q&A/chatbot',
        title: 'Chatbot',
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: 'CameraFi',
    pages: camerafiSection,
  },
  {
    title: 'CameraFi Live',
    pages: camerafiLiveSection,
  },
  {
    title: 'CameraFi Studio',
    pages: studioSection,
  },
  {
    title: 'Camerafi Shopping',
    pages: camerafiShoppingSection,
  },
  {
    title: 'ai',
    pages: ai,
  },
];

export default navItems;
