import axios, { AxiosError, AxiosResponse } from 'axios';

type ServerType = 'live' | 'studio' | 'chatbot' | 'crawling';

const CRAWLING_SERVER_URL =
  'https://crawling-service-api-h75ipgv4wq-uc.a.run.app';

const getServerURL = (ServerType: ServerType) => {
  switch (ServerType) {
    case 'studio':
      return `${process.env.REACT_APP_CAMERAFI_API_GATEWAY_URL}`;
    case 'live':
      return `${process.env.REACT_APP_SERVER_HOST}`;
    case 'chatbot':
      return `${process.env.REACT_APP_CHATBOT_API_URL}`;
    case 'crawling':
      return CRAWLING_SERVER_URL;
  }
};

const fetchJSON =
  (serverType: ServerType) =>
  (
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    token: string,
    url: string,
    data?: {},
  ) =>
    fetch(getServerURL(serverType) + url, {
      method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: data ? JSON.stringify(data) : null,
    }).then(async (res) => {
      let response = null;
      if (Number(res.headers.get('content-length'))) {
        response = await res.json();
      }
      if (res.ok) {
        return response;
      } else {
        return Promise.reject(new Error(response.message || 'Unknown Error'));
      }
    });

const fetchFormData =
  (serverType: ServerType) =>
  (
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    token: string,
    url: string,
    data: FormData = new FormData(),
  ) =>
    fetch(getServerURL(serverType) + url, {
      method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: data,
    }).then(async (res) => {
      let response = null;
      if (Number(res.headers.get('content-length'))) {
        response = await res.json();
      }
      if (res.ok) {
        return response;
      } else {
        return Promise.reject(new Error(response.message || 'Unknown Error'));
      }
    });

const fetchAxios =
  (serverType: ServerType) =>
  async (
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    token: string,
    url: string,
    body?: object,
  ) =>
    axios({
      method,
      url: getServerURL(serverType) + url,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res: AxiosResponse) => res.data)
      .catch((error: AxiosError<{ message: string }>) =>
        Promise.reject(error.response?.data.message ?? error.message),
      );

export const fetchLiveJSON = fetchJSON('live');
export const fetchStudioJSON = fetchJSON('studio');
export const fetchChatbotJSON = fetchJSON('chatbot');
export const fetchChatbotFormData = fetchFormData('chatbot');
export const fetchStudioAxios = fetchAxios('studio');
