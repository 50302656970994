import {
  Container as MuiContainer,
  Fab as MuiFab,
  styled,
} from '@mui/material';
import { media } from '@styles/theme';

export const Container = styled(MuiContainer)`
  padding: 0px;
`;

export const Fab = styled(MuiFab)`
  bottom: ${(props) => props.theme.spacing(8)};
  position: fixed;
  right: ${(props) => props.theme.spacing(8)};
  z-index: 1;

  ${media.mobile} {
    bottom: ${(props) => props.theme.spacing(4)};
    right: ${(props) => props.theme.spacing(4)};
  }
`;

export const ProgressWrapper = styled('div')`
  margin: 48px;
  text-align: center;
`;

export const MoreWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const EmptyData = styled('div')`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
