import { SidebarItemsType } from '@usertypes/sidebar';
import reduceChildRoutes from './reduceChildRoutes';

const SidebarNavList: React.FC<{
  depth: number;
  pages: SidebarItemsType[];
  pathname: string;
}> = ({ pages, depth, pathname }) => {
  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, depth, pathname }),
    [] as JSX.Element[],
  );

  return <>{childRoutes}</>;
};

export default SidebarNavList;
