import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import type {
  UserListInfos,
  UserQueryContextType,
  UserRole,
} from '@usertypes/user';

const UserQueryContext = createContext<UserQueryContextType | null>(null);

const UserQueryProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  const initialUserListInfos: UserListInfos = useMemo(
    () => ({
      createdAtOrder: 'DESC',
      lastActivityTimeOrder: undefined,
      email: '',
      limit: 10,
      page: 0,
      username: '',
      liveStatus: 'all',
      camerafiLiveIOSUserStatus: 'all',
      studioStatus: 'all',
      shoppingStatus: 'all',
      lastActivity: undefined,
      language: 'all',
      country: 'all',
    }),
    [],
  );

  const [showUserListInfos, setShowUserListInfos] =
    useState<UserListInfos>(initialUserListInfos);

  useEffect(() => {
    setShowUserListInfos(initialUserListInfos);
  }, [initialUserListInfos, location.pathname]);

  const changeSort = (orderBy: string) => {
    const showUserListInfoValueToApply: UserListInfos = {
      ...showUserListInfos,
      page: 0,
      createdAtOrder:
        orderBy === 'createdAt'
          ? showUserListInfos.createdAtOrder === 'ASC'
            ? 'DESC'
            : 'ASC'
          : undefined,
      lastActivityTimeOrder:
        orderBy === 'lastActivity'
          ? showUserListInfos.lastActivityTimeOrder === 'ASC'
            ? 'DESC'
            : 'ASC'
          : undefined,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };

  const changePage = (newPage: number) => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: newPage,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };

  const changeRowsPerPage = (newLimit: string) => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      limit: parseInt(newLimit, 10),
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };

  const refresh = () => {
    setShowUserListInfos({ ...showUserListInfos });
  };

  const searchByEmail = (emailSearchValue: string) => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      email: emailSearchValue,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };

  const cancelEmailSearch = () => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      email: '',
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };

  const searchByName = (nameSearchValue: string) => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      username: nameSearchValue,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };

  const cancelNameSearch = () => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      username: '',
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };

  const searchByLiveStatus = (
    platform: 'all' | 'iOS' | 'Android',
    status: UserRole | 'all',
  ) => {
    let liveStatus: UserRole | 'all' = 'all';
    let camerafiLiveIOSUserStatus: UserRole | 'all' = 'all';

    if (platform === 'all') {
      liveStatus = status;
      camerafiLiveIOSUserStatus = status;
    } else if (platform === 'iOS') {
      camerafiLiveIOSUserStatus = status;
    } else {
      liveStatus = status;
    }

    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      liveStatus,
      camerafiLiveIOSUserStatus,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };
  const searchByStudioStatus = (status: UserRole | 'all') => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      studioStatus: status,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };
  const searchByShoppingStatus = (status: UserRole | 'all') => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      shoppingStatus: status,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };
  const searchByLanguage = (language: string | 'all') => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      language,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };
  const searchByCountry = (country: string | 'all') => {
    const showUserListInfoValueToApply = {
      ...showUserListInfos,
      page: 0,
      country,
    };

    setShowUserListInfos(showUserListInfoValueToApply);
  };

  const reset = () => {
    setShowUserListInfos(initialUserListInfos);
  };

  return (
    <UserQueryContext.Provider
      value={{
        cancelEmailSearch,
        cancelNameSearch,
        changePage,
        changeRowsPerPage,
        changeSort,
        refresh,
        reset,
        searchByEmail,
        searchByLiveStatus,
        searchByName,
        searchByShoppingStatus,
        searchByStudioStatus,
        searchByLanguage,
        searchByCountry,
        showUserListInfos,
      }}
    >
      {children}
    </UserQueryContext.Provider>
  );
};

export { UserQueryContext, UserQueryProvider };
