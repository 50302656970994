import { TextField, styled } from '@mui/material';

export const Search = styled('div')<{ searchFilterOn: boolean }>`
  background: ${(props) =>
    props.searchFilterOn && props.theme.palette.primary.main};
  border-radius: 100px;
  margin: 0 20px 10px 0;
  position: relative;
  width: 250px;
`;

export const SearchIconWrapper = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  z-index: 1;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const ClearIconWrapper = styled('div')`
  align-items: center;
  color: ${({ theme }) => theme.palette.text.headbar};
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 15px;
  z-index: 1;

  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Input = styled(TextField)`
  font-size: inherit;
  line-height: inherit;
  padding-bottom: ${(props) => props.theme.spacing(1)};
  padding-left: ${(props) => props.theme.spacing(3.5)};
  padding-right: ${(props) => props.theme.spacing(2.5)};
  padding-top: ${(props) => props.theme.spacing(1)};
  width: calc(100% - 20px);
  .MuiFormHelperText-root {
    bottom: -15px;
    position: absolute;
  }
`;

export const SearchFilter = styled('div')`
  color: ${({ theme }) => theme.palette.text.headbar};
  height: 100%;
  overflow: hidden;
  padding-bottom: ${(props) => props.theme.spacing(1)};
  padding-left: ${(props) => props.theme.spacing(3.5)};
  padding-right: ${(props) => props.theme.spacing(2.5)};
  padding-top: ${(props) => props.theme.spacing(1)};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
`;
