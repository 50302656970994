import { PaletteMode } from '@mui/material';

const calcRem = (size: number) => `${size / 13}rem`;

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: '#174375',
          },
          error: {
            main: '#d32f2f',
          },
          text: {
            bold: '#000000',
            headbar: '#f0f6fb',
            placeholder: '#999999',
            primary: '#333333',
            secondary: '#666666',
          },
          background: {
            default: '#fafafa',
            headbar: '#24292f',
            menubar: '#fafafa',
            sidebar: '#fafafa',
          },
          divider: '#f0f0f0',
        }
      : {
          text: {
            bold: '#f0f0f0',
            headbar: '#ffffff',
            placeholder: '#c2c3c4',
            primary: '#c9d1d9',
            secondary: '#7d858e',
          },
          background: {
            default: '#0d1116',
            headbar: '#161b22',
            menubar: '#0d1116',
            sidebar: '#0d1116',
          },
          divider: '#21262d',
        }),
  },
  typography: {
    fontFamily:
      'Pretendard, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sawns-serif',
    fontSize: 13,
    h1: {
      fontSize: calcRem(34),
    },
    h2: {
      fontSize: calcRem(20),
    },
  },
  transparent: 'transparent',
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: mode === 'light' ? '#fafafa' : '#0d1116',
          backgroundImage: 'none',
        },
      },
    },
  },
});

const customMediaQuery = (maxWidth: number): string =>
  `@media (max-width: ${maxWidth}px)`;

export const media = {
  custom: customMediaQuery,
  pc: customMediaQuery(1440),
  tablet: customMediaQuery(768),
  mobile: customMediaQuery(576),
};
