import { useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { getUserRequest } from '@api/user';
import useAuth from '@hooks/useAuth';

const AuthGuard = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, token, user } = useAuth();
  const [authorized, setAuthorized] = useState(false);
  const navigate = useNavigate();

  const authCheck = async () => {
    if (isAuthenticated === undefined) {
      setAuthorized(false);
    } else if (isAuthenticated === true) {
      try {
        const serverUser = await getUserRequest(token, user?.id);
        if (serverUser.role === 'role-admin') {
          setAuthorized(true);
        } else {
          setAuthorized(false);
          navigate('/');
        }
      } catch (error) {
        setAuthorized(false);
        navigate('/');
      }
    } else {
      setAuthorized(false);
      navigate('/');
    }
  };

  useEffect(() => {
    authCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, token]);

  return authorized ? <>{children}</> : <></>;
};

export default AuthGuard;
