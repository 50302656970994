import { Box, CircularProgress } from '@mui/material';

const Loader = () => (
  <Box
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      minHeight: '100%',
    }}
  >
    <CircularProgress color="secondary" />
  </Box>
);

export default Loader;
