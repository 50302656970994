import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';

const TableSkeleton = ({
  itemHeight = 65,
  length = 10,
}: {
  itemHeight?: number;
  length?: number;
}) => (
  <TableBody>
    <TableRow>
      <TableCell sx={{ padding: '0 15px' }}>
        <Skeleton variant="text" height={56} />
      </TableCell>
    </TableRow>
    {[...Array(length)].map((_, index) => (
      <TableRow key={index}>
        <TableCell sx={{ padding: '0 15px' }}>
          <Skeleton variant="text" height={itemHeight} />
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);

export default TableSkeleton;
