import async from '@components/atoms/Async';
import AuthLayout from '@components/templates/AuthLayout';
import DashboardLayout from '@components/templates/DashboardLayout';
import BroadcastLive from '@pages/broadcast/BroadcastLive';
import BroadcastVod from '@pages/broadcast/BroadcastVod';
import ResetPassword from '@pages/auth/ResetPassword';
import SignIn from '@pages/auth/SignIn';
import SignUp from '@pages/auth/SignUp';
import EventList from '@pages/shopify/EventList';
import CreateEvent from '@pages/createEvent';

const AdminList = async(() => import('@pages/user/AdminList'));
const BlockedList = async(() => import('@pages/user/BlockedList'));
const Chatbot = async(() => import('@pages/q&a/chatbot'));
const CouponList = async(() => import('@pages/coupon/CouponList'));
const CreateCoupon = async(() => import('@pages/coupon/Create'));
const Default = async(() => import('@pages/dashboards/Default'));
const Faq = async(() => import('@pages/helpCenter/Faq'));
const Match = async(() => import('@pages/match'));
const MatchDetail = async(() => import('@pages/match/MatchDetail'));
const NewManual = async(() => import('@pages/helpCenter/NewManual'));
const Notice = async(() => import('@pages/helpCenter/Notice'));
const Notification = async(() => import('@pages/Notification'));
const Page404 = async(() => import('@pages/auth/Page404'));
const PlayStore = async(() => import('@pages/q&a/playStore'));
const ShopDetail = async(() => import('@pages/shopify/ShopDetail'));
const ShopList = async(() => import('@pages/shopify/ShopList'));
const UpdateCoupon = async(() => import('@pages/coupon/Update'));
const UserGuide = async(() => import('@pages/helpCenter/UserGuide'));
const UserList = async(() => import('@pages/user/UserList'));
const VideoPlayer = async(() => import('@pages/broadcast/VideoPlayer'));

const routes = [
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '',
        element: <SignIn />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'sign-up',
        element: <SignUp />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <Default />,
      },
    ],
  },
  {
    path: '/account',
    element: <DashboardLayout />,
    children: [
      {
        path: 'user',
        element: <UserList />,
      },
      {
        path: 'blocked',
        element: <BlockedList />,
      },
      {
        path: 'admin',
        element: <AdminList />,
      },
    ],
  },
  {
    path: '/notification',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <Notification />,
      },
    ],
  },
  {
    path: '/broadcast',
    element: <DashboardLayout />,
    children: [
      {
        path: 'live',
        element: <BroadcastLive />,
      },
      {
        path: 'vod',
        element: <BroadcastVod />,
      },
      {
        path: 'live/:id',
        element: <VideoPlayer />,
      },
      {
        path: 'vod/:id',
        element: <VideoPlayer />,
      },
    ],
  },
  {
    path: '/match',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Match /> },
      { path: ':id', element: <MatchDetail /> },
    ],
  },
  {
    path: '/coupon',
    element: <DashboardLayout />,
    children: [
      {
        path: 'list',
        element: <CouponList />,
      },
      {
        path: 'create',
        element: <CreateCoupon />,
      },
      {
        path: 'update',
        element: <UpdateCoupon />,
      },
    ],
  },
  {
    path: '/camerafiShopping',
    element: <DashboardLayout />,
    children: [
      {
        path: 'shops',
        element: <ShopList />,
      },
      {
        path: 'events',
        element: <EventList />,
      },
      {
        path: 'shops/:id',
        element: <ShopDetail />,
      },
    ],
  },
  {
    path: '/help-center',
    element: <DashboardLayout />,
    children: [
      {
        path: 'user-guide',
        element: <UserGuide />,
      },
      {
        path: 'notice',
        element: <Notice />,
      },
      {
        path: 'faq',
        element: <Faq />,
      },
      {
        path: 'new-manual',
        element: <NewManual />,
      },
    ],
  },
  {
    path: '/Q&A',
    element: <DashboardLayout />,
    children: [
      {
        path: 'chatbot',
        element: <Chatbot />,
      },
      {
        path: 'playStore',
        element: <PlayStore />,
      },
    ],
  },
  {
    path: '/create-event',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: <CreateEvent />,
      },
    ],
  },
  {
    path: '*',
    element: <DashboardLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
