import { styled } from '@mui/material';
import { Refresh } from '@mui/icons-material';

export const RefreshIcon = styled(Refresh, {
  shouldForwardProp: (prop) => prop !== 'isFetching',
})<{ isFetching: boolean }>`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: ${({ isFetching }) =>
    isFetching ? 'spin 1s ease-in-out infinite' : 'none'};
`;
