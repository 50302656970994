import { useState } from 'react';
import { FirebaseError } from 'firebase/app';
import { useFormik } from 'formik';
import { useNavigate, Link } from 'react-router-dom';
import { object, string } from 'yup';
import { Checkbox, FormControlLabel, Button } from '@mui/material';

import Google from '@components/atoms/Icons/Google';
import { Alert, TextField } from '@components/atoms/Spacing';
import useAuth from '@hooks/useAuth';
import { firebaseErrorCode } from '@utils/firebaseErrorCode';

import { OrDiv } from './SignIn.style';

const SignIn = () => {
  const navigate = useNavigate();
  const { signIn, signInWithGoogle } = useAuth();
  const [error, setError] = useState('');
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: false,
    },
    validationSchema: object().shape({
      email: string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: string().max(255).required('Password is required'),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        await signIn(values.email, values.password);
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          const message = firebaseErrorCode(error.code);
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      {formik.errors.submit && (
        <Alert mt={2} mb={3} severity="warning">
          {formik.errors.submit}
        </Alert>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        type="email"
        name="email"
        label="Email Address"
        value={formik.values.email}
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        my={1}
      />
      <TextField
        type="password"
        name="password"
        label="Password"
        value={formik.values.password}
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        my={1}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={formik.isSubmitting}
      >
        Sign in
      </Button>
      <OrDiv>or</OrDiv>
      <Button
        variant="outlined"
        startIcon={<Google />}
        fullWidth
        onClick={async () => {
          try {
            await signInWithGoogle();
            navigate('/');
          } catch (error: any) {
            setError(`Google Sign-in ${firebaseErrorCode(error.code)}`);
          }
        }}
      >
        Coutinue with Google
      </Button>
      <Button component={Link} to="/reset-password" fullWidth color="primary">
        Forgot password
      </Button>
      <Button component={Link} to="/sign-up" fullWidth color="primary">
        Sign up
      </Button>
    </form>
  );
};

export default SignIn;
