import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { Divider } from '@components/atoms/Spacing';

import { HeaderProps } from './Header.type';

const Header = ({ title, subTitle, children }: HeaderProps) => {
  const theme = useTheme();
  const isNotXs = useMediaQuery(theme.breakpoints.not('xs'));

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item marginBottom={2} marginRight={6}>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          {subTitle}
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
      <Divider my={isNotXs ? 6 : 2} />
    </>
  );
};

export default Header;
