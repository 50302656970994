import { PlanUserType } from '@usertypes/paddle';
import { AdminRole, User, UserRole, UserStatistic } from '@usertypes/user';
import {
  fetchLiveJSON,
  fetchStudioAxios,
  fetchStudioJSON,
} from '@utils/api-helpers';

export const getUserRequest = (token: string, id: string) =>
  fetchLiveJSON('GET', token, '/api/v1/users/' + id) as Promise<User>;

export const getUsersRequest = ({
  token,
  page,
  limit,
  order,
  email,
  username,
  role = 'role-user',
  liveStatus = undefined,
  camerafiLiveIOSUserStatus = undefined,
  studioStatus = undefined,
  shoppingStatus = undefined,
  lastActivity = undefined,
  isBlocked,
  language = 'all',
  country = 'all',
}: {
  token: string;
  page: number;
  limit: number;
  order: {
    createdAtOrder?: string;
    lastActivityTimeOrder?: string;
  };
  email: string;
  username: string;
  role: 'role-user' | 'role-admin';
  liveStatus: UserRole | 'all' | undefined;
  camerafiLiveIOSUserStatus?: UserRole | 'all' | undefined;
  studioStatus: UserRole | 'all' | undefined;
  shoppingStatus: UserRole | 'all' | undefined;
  lastActivity?: { from: string; to: string } | undefined;
  isBlocked?: boolean;
  language?: string | 'all';
  country?: string | 'all';
}) => {
  let query =
    '/api/v1/users/req-by-admin?' +
    `page=${page}` +
    `&limit=${limit}` +
    `&email=${email}` +
    `&username=${username}` +
    `&role=${role}`;

  if (order.createdAtOrder && order.lastActivityTimeOrder === undefined) {
    query += `&createdAtOrder=${order.createdAtOrder}`;
  } else if (
    order.createdAtOrder === undefined &&
    order.lastActivityTimeOrder
  ) {
    query += `&lastActivityTimeOrder=${order.lastActivityTimeOrder}`;
  } else {
    query += '&createdAtOrder=DESC';
  }

  if (liveStatus !== 'all') {
    query += `&camerafiLive=${liveStatus === undefined ? null : liveStatus}`;
  }

  if (
    camerafiLiveIOSUserStatus !== 'all' &&
    camerafiLiveIOSUserStatus !== undefined
  ) {
    query += `&camerafiLiveIOSUserStatus=${camerafiLiveIOSUserStatus}`;
  }

  if (studioStatus !== 'all') {
    query += `&camerafiStudio=${
      studioStatus === undefined ? null : studioStatus
    }`;
  }

  if (shoppingStatus !== 'all') {
    query += `&camerafiShopping=${
      shoppingStatus === undefined ? null : shoppingStatus
    }`;
  }

  if (lastActivity !== undefined) {
    query += `&from=${lastActivity.from || ''}&to=${lastActivity.to || ''}`;
  }

  if (isBlocked !== undefined) {
    query += `&blockedByAdmin=${isBlocked ?? ''}`;
  }

  if (language !== 'all') {
    query += `&language=${language}`;
  }

  if (country !== 'all') {
    query += `&country=${country}`;
  }

  return fetchLiveJSON('GET', token, query) as Promise<{
    pageCount: number;
    totalCount: number;
    users: User[];
  }>;
};

export const getAdminUsers = (
  token: string,
  page: number,
  limit: number,
  createdAtOrder: string,
  email: string,
  username: string,
) =>
  getUsersRequest({
    token,
    page,
    limit,
    order: { createdAtOrder },
    email,
    username,
    role: 'role-admin',
    liveStatus: 'all',
    studioStatus: 'all',
    shoppingStatus: 'all',
    lastActivity: undefined,
    isBlocked: false,
  });

export const getDisabledUsers = (
  token: string,
  page: number,
  limit: number,
  createdAtOrder: string,
  email: string,
  username: string,
) =>
  getUsersRequest({
    token,
    page,
    limit,
    order: { createdAtOrder },
    email,
    username,
    role: 'role-user',
    liveStatus: 'all',
    studioStatus: 'all',
    shoppingStatus: 'all',
    lastActivity: undefined,
    isBlocked: true,
  });

export const getStudioUsersRequest = (token: string) =>
  fetchStudioJSON('GET', token, '/studio/api/v1/users/subscriber') as Promise<
    PlanUserType[]
  >;

export const updateUserRequest = (
  token: string,
  uid: string,
  role: AdminRole,
) =>
  fetchLiveJSON('PUT', token, '/api/v1/users/role/' + uid, { role }) as Promise<
    Omit<User, 'callId' | 'profileUrl' | 'username'>
  >;

export const deleteUserRequest = (token: string, uid: string) =>
  fetchLiveJSON('DELETE', token, '/api/v1/users/' + uid) as Promise<null>;

export const disableUserRequest = (
  token: string,
  uid: string,
  isBlock: boolean,
  reason?: string,
) =>
  fetchLiveJSON('PUT', token, '/api/v1/users/block/' + uid, {
    isBlock,
    blockedReason: reason || '-',
  }) as Promise<null>;

export const getUserStatistics = (
  token: string,
  from: string,
  to: string,
): Promise<UserStatistic[]> =>
  fetchLiveJSON('GET', token, `/api/v1/users/statistics?from=${from}&to=${to}`);

export const getStudioUserUid = async (
  token: string,
  nickname: string,
): Promise<string> => {
  const result = await fetchStudioAxios(
    'GET',
    token,
    `/studio/api/v1/users/uid?nickname=${nickname}`,
  );

  return result;
};
