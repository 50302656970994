import { useRoutes } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { GlobalStyles } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import { AuthProvider } from '@contexts/AuthContext';
import { UserQueryProvider } from '@contexts/UserQueryContext';
import { getDesignTokens } from '@styles/theme';
import { errorMessage } from '@utils/utilFunctions';

import routes from './routes';
import { ShoppingEventQueryProvider } from '@contexts/ShoppingEventQueryContext';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const content = useRoutes(routes);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => {
        const key = enqueueSnackbar(errorMessage(error), {
          variant: 'error',
          onClick: () => closeSnackbar(key),
        });
      },
    }),

    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const colorMode = prefersDarkMode ? 'dark' : 'light';
  document
    .querySelector('meta[name="theme-color"]')
    ?.setAttribute('content', prefersDarkMode ? '#f0f6fb' : '#0d1116');
  const theme = createTheme(getDesignTokens(colorMode));

  const globalStyle = (
    <GlobalStyles
      styles={(theme) => ({
        'html, body, #root': {
          height: '100%',
          padding: 0,
          margin: 0,
          boxSizing: 'border-box',
          fontFamily: theme.typography.fontFamily,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          background: theme.palette.background.default,
          color: theme.palette.text.primary,
          wordBreak: 'keep-all',
          WebkitTextSizeAdjust: 'none',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        },
        '#__next': {
          height: '100%',
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
        '*': {
          boxSizing: 'border-box',
        },
      })}
    />
  );

  //TODO: 가능하다면 page 단으로 provider 이동
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {globalStyle}
        <CssBaseline />
        <AuthProvider>
          <UserQueryProvider>
            <ShoppingEventQueryProvider>{content}</ShoppingEventQueryProvider>
          </UserQueryProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
