import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { getApps, initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getAuth,
  onIdTokenChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import {
  DocumentData,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { useSnackbar } from 'notistack';

import { signUpRequest } from '@api/auth';
import { getUserRequest } from '@api/user';
import {
  FirebaseAuthContextType,
  ActionMap,
  AuthState,
  AuthUser,
} from '@usertypes/auth';
import { errorMessage } from '@utils/utilFunctions';

import { firebaseConfig } from '../config';

const INITIALIZE = 'INITIALIZE';

if (!getApps().length) {
  initializeApp(firebaseConfig);
  setPersistence(getAuth(), browserLocalPersistence);
}

const initialState: AuthState = {
  isAuthenticated: undefined,
  isInitialized: undefined,
  user: null,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseAuthContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [profile, setProfile] = useState<DocumentData | undefined>();
  const [token, setToken] = useState('');
  const [role, setRole] = useState('role-user');

  const auth = getAuth();
  const db = getFirestore();

  useEffect(
    () =>
      onIdTokenChanged(auth, async (user) => {
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setProfile(docSnap.data());
          }
          const newToken = await user.getIdToken();
          setToken(newToken);
          try {
            const serverUser = await getUserRequest(newToken, user.uid);
            setRole(serverUser.role);
            dispatch({
              type: INITIALIZE,
              payload: { isAuthenticated: true, user },
            });
          } catch (error) {
            await signOut();
            const key = enqueueSnackbar(
              '권한이 없습니다.\n관리자의 승인이 필요합니다.',
              {
                onClick: () => closeSnackbar(key),
                variant: 'error',
              },
            );
            navigate('/');
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const signIn = (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password).then(async (res) => {
      if (res.user.emailVerified) {
        const apiToken = await res.user.getIdToken();
        try {
          const user = await getUserRequest(apiToken, res.user.uid);
          setRole(user.role);
          if (user.role === 'role-admin') {
            navigate('/');
          } else if (user.role === 'role-user') {
            navigate('/');
            const key = enqueueSnackbar(
              '권한이 없습니다.\n관리자의 승인이 필요합니다.',
              {
                onClick: () => closeSnackbar(key),
                variant: 'error',
              },
            );
          }
        } catch (error) {
          const key = enqueueSnackbar(
            '로그인 중인 유저정보를 가지고오지 못했습니다.\n' +
              errorMessage(error),
            {
              onClick: () => closeSnackbar(key),
              variant: 'error',
            },
          );
        }
      } else {
        const key = enqueueSnackbar('이메일 확인이 필요합니다.', {
          onClick: () => closeSnackbar(key),
          variant: 'error',
        });
      }
    });

  const signInWithGoogle = () =>
    signInWithPopup(
      auth,
      new GoogleAuthProvider().setCustomParameters({
        prompt: 'select_account',
      }),
    ).then(async (res) => {
      const isNewUser = getAdditionalUserInfo(res)?.isNewUser;
      const user = res.user;
      const uid = user.uid;
      const email = user.email;
      const username = user.displayName;
      const photoUrl = user.photoURL;

      const apiToken = await user.getIdToken();
      if (isNewUser) {
        try {
          await signUpRequest(
            apiToken,
            uid,
            email || '',
            username || '',
            photoUrl || '',
          );
          setRole('role-user');
          navigate('/');
          const key = enqueueSnackbar(
            '회원가입이 완료되었습니다.\n관리자의 승인이 필요합니다.',
            {
              onClick: () => closeSnackbar(key),
              variant: 'info',
            },
          );
        } catch (error) {
          const key = enqueueSnackbar(
            '회원가입이 되지 않았습니다.\n' + errorMessage(error),
            {
              onClick: () => closeSnackbar(key),
              variant: 'error',
            },
          );
        }
      } else {
        try {
          const user = await getUserRequest(apiToken, uid);
          setRole(user.role);
          if (user.role === 'role-admin') {
            navigate('/');
          } else if (user.role === 'role-user') {
            navigate('/');
            const key = enqueueSnackbar(
              '권한이 없습니다.\n관리자의 승인이 필요합니다.',
              {
                onClick: () => closeSnackbar(key),
                variant: 'error',
              },
            );
          }
        } catch (error) {
          let message = 'Unknown Error';
          if (error instanceof Error) message = error.message;
          const key = enqueueSnackbar(
            '로그인 중인 유저정보를 가지고오지 못했습니다.\n' + message,
            {
              onClick: () => closeSnackbar(key),
              variant: 'error',
            },
          );
          if (message === 'user/not-found') {
            try {
              await signUpRequest(
                apiToken,
                uid,
                email || '',
                username || '',
                photoUrl || '',
              );
              setRole('role-user');
              navigate('/');
              const key = enqueueSnackbar(
                '회원가입이 완료되었습니다.\n관리자의 승인이 필요합니다.',
                {
                  onClick: () => closeSnackbar(key),
                  variant: 'info',
                },
              );
            } catch (error) {
              const key = enqueueSnackbar(
                '회원가입이 되지 않았습니다.\n' + message,
                {
                  onClick: () => closeSnackbar(key),
                  variant: 'error',
                },
              );
            }
          }
        }
      }

      await setDoc(
        doc(db, 'users', res.user.uid),
        {
          displayName: username,
          email: email,
          uid: uid,
          photoUrl: photoUrl,
        },
        { merge: true },
      );
    });

  const signUp = (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) =>
    createUserWithEmailAndPassword(auth, email, password).then(async (res) => {
      await sendEmailVerification(res.user).then(async () => {
        await setDoc(doc(db, 'users', res.user?.uid), {
          displayName: `${firstName} ${lastName}`,
          email,
          uid: res.user?.uid,
        });

        const apiToken = await res.user.getIdToken();
        try {
          await signUpRequest(
            apiToken,
            res.user?.uid,
            email,
            `${firstName} ${lastName}`,
            '',
          );
          navigate('/');
          const key = enqueueSnackbar(
            '회원가입이 완료되었습니다.\n관리자의 승인이 필요합니다.',
            {
              onClick: () => closeSnackbar(key),
              variant: 'info',
            },
          );
        } catch (error) {
          const key = enqueueSnackbar(
            '회원가입이 되지 않았습니다.\n' + errorMessage(error),
            {
              onClick: () => closeSnackbar(key),
              variant: 'error',
            },
          );
        }
      });
    });

  const signOut = async () => {
    try {
      await auth.signOut();
      dispatch({
        type: INITIALIZE,
        payload: { isAuthenticated: false, user: null },
      });
      navigate('/');
    } catch (error) {
      return Promise.reject(
        new Error('로그아웃에 실패했습니다.' + errorMessage(error)),
      );
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      navigate('/');
    } catch (error) {
      return Promise.reject(new Error(errorMessage(error)));
    }
  };

  const authUser = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        token,
        user: {
          id: authUser.uid,
          email: authUser.email,
          avatar: authUser.avatar || profile?.avatar,
          displayName: authUser.displayName || profile?.displayName,
          photoURL: authUser.photoURL,
          role,
        },
        signIn,
        signUp,
        signInWithGoogle,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
