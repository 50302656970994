import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography as MuiTypography,
  styled,
} from '@mui/material';
import { spacing } from '@mui/system';

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled('div')(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Typography = styled(MuiTypography)(spacing);

export {
  Alert,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Paper,
  Spacer,
  TextField,
  Typography,
};
