import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import Row from '@components/molecules/TableRow';
import TableHead from '@components/molecules/TableHead';
import TableToolbar from '@components/molecules/TableToolbar';
import RowDetails from '@components/molecules/RowDetails';
import { Order } from '@usertypes/table';

import TableSkeleton from './TableSkeleton';
import { ResponsiveTableProps } from './ResponsiveTable.type';

const ResponsiveTable = <ItemType extends unknown>({
  datas,
  defaultDetailItem,
  densePadding = false,
  fetchingItems,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDefaultDetailItemList,
  handleDetailItemList,
  handleRefresh,
  handleRowClick,
  handleRowItemList,
  handleSort,
  headCells,
  idExtractor,
  isDeleting,
  isFetching,
  isLoading,
  limit,
  page,
  preFetch,
  responsive,
  selectIdExtractor,
  skeletonRowHeight,
  tableActions,
  tableName,
  totalCount,
}: ResponsiveTableProps<ItemType>) => {
  const CUSTOM_MD = 1000;

  const theme = useTheme();

  const [dense, setDense] = useState(densePadding);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState<readonly string[]>([]);

  const isXlUp = useMediaQuery(theme.breakpoints.up('xl'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isMdUp = useMediaQuery(theme.breakpoints.up(CUSTOM_MD));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setSelected([]);
  }, [datas]);

  const offsetByWidth = useCallback(() => {
    if (responsive) {
      if (isXlUp) {
        return 0;
      } else if (isLgUp) {
        return 1;
      } else if (isMdUp) {
        return 2;
      } else if (isSmUp) {
        return 3;
      } else {
        return 4;
      }
    } else {
      return 0;
    }
  }, [isLgUp, isMdUp, isSmUp, isXlUp, responsive]);

  const getResponsiveHeadCells = () => {
    const headCellsFromProps = headCells;
    return headCellsFromProps.slice(
      0,
      headCellsFromProps.length - offsetByWidth(),
    );
  };

  const getResponsiveRowItemList = (data: ItemType) => {
    const rowItemList = handleRowItemList(data);
    return rowItemList.slice(0, rowItemList.length - offsetByWidth());
  };

  const getResponsiveDetailItemList = (data: ItemType) => {
    const defaultDetailItemList = handleDefaultDetailItemList
      ? handleDefaultDetailItemList(data)
      : [];
    const detailItemListFromProps = handleDetailItemList
      ? handleDetailItemList(data)
      : [];

    const responsiveRowItemList = detailItemListFromProps.slice(
      detailItemListFromProps.length - offsetByWidth() < 0
        ? 0
        : detailItemListFromProps.length - offsetByWidth(),
      detailItemListFromProps.length,
    );

    return responsiveRowItemList.concat(defaultDetailItemList);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, id: string) => {
    const isAsc = orderBy === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(id);
    if (handleSort) handleSort(id);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && selectIdExtractor) {
      const newSelectedIds = datas.map((data: ItemType) =>
        selectIdExtractor(data),
      );
      setSelected(newSelectedIds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    _event: React.MouseEvent<unknown>,
    selectedRowId: string,
  ) => {
    const selectedIndex = selected.indexOf(selectedRowId);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, selectedRowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (selectedRowId: string) =>
    selected.indexOf(selectedRowId) !== -1;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableToolbar
            dense={dense}
            handleChangeDense={handleChangeDense}
            handleRefresh={handleRefresh}
            header={tableName ?? ''}
            isFetching={isFetching}
            selected={selected}
          >
            {tableActions ? tableActions(selected) : null}
          </TableToolbar>
          <TableContainer>
            <Table
              aria-label="table"
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              {isLoading ? (
                <TableSkeleton
                  itemHeight={skeletonRowHeight}
                  length={datas.length === 0 ? undefined : datas.length}
                />
              ) : (
                <>
                  <TableHead
                    headCells={getResponsiveHeadCells()}
                    numSelected={
                      selectIdExtractor ? selected.length : undefined
                    }
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    order={order}
                    orderBy={handleSort ? orderBy : undefined}
                    rowCount={datas.length}
                  />

                  <TableBody>
                    {datas.map((data: ItemType, index: number) => (
                      <Row
                        data={data}
                        fetchingItems={fetchingItems}
                        handleClick={handleClick}
                        handleRowClick={handleRowClick}
                        index={index}
                        isSelected={isSelected}
                        isFetching={isFetching || (isDeleting ?? false)}
                        itemList={getResponsiveRowItemList(data)}
                        key={idExtractor(data)}
                        labelIdPrefix={tableName}
                        preFetch={preFetch}
                        isExpandable={Boolean(
                          getResponsiveDetailItemList(data).length,
                        )}
                        selectId={
                          selectIdExtractor
                            ? selectIdExtractor(data)
                            : undefined
                        }
                        skeletonRowHeight={skeletonRowHeight}
                      >
                        <RowDetails
                          defaultDetailItem={
                            defaultDetailItem
                              ? defaultDetailItem(data)
                              : undefined
                          }
                          dense={dense}
                          itemList={getResponsiveDetailItemList(data)}
                        />
                      </Row>
                    ))}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
          {handleChangePage &&
          handleChangeRowsPerPage &&
          totalCount !== undefined &&
          page !== undefined &&
          limit !== undefined ? (
            <TablePagination
              component="div"
              count={totalCount}
              onPageChange={(_event: unknown, newPage: number) =>
                handleChangePage(newPage)
              }
              onRowsPerPageChange={(event: { target: { value: string } }) => {
                setSelected([]);
                handleChangeRowsPerPage(event.target.value);
              }}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          ) : null}
        </Paper>
      </Box>
    </>
  );
};

export default ResponsiveTable;
