import { useState } from 'react';
import { useFormik } from 'formik';
import { FirebaseError } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { object, ref, string } from 'yup';
import { Button } from '@mui/material';

import Google from '@components/atoms/Icons/Google';
import { Alert, TextField } from '@components/atoms/Spacing';
import useAuth from '@hooks/useAuth';
import { firebaseErrorCode } from '@utils/firebaseErrorCode';

import { OrDiv } from './SignUp.style';

const SignUp = () => {
  const navigate = useNavigate();
  const { signUp, signInWithGoogle } = useAuth();
  const [error, setError] = useState('');
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      submit: false,
    },
    validationSchema: object().shape({
      firstName: string().max(255).required('First name is required'),
      lastName: string().max(255).required('Last name is required'),
      email: string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: string()
        .min(8, 'Must be at least 8 characters')
        .max(255)
        .required('Required'),
      confirmPassword: string().when('password', {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: (schema) =>
          schema.oneOf([ref('password')], 'Both password need to be the same'),
      }),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        await signUp(
          values.email,
          values.password,
          values.firstName,
          values.lastName,
        );
        navigate('/');
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          const message = firebaseErrorCode(error.code);
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      {formik.errors.submit && (
        <Alert severity="error">{formik.errors.submit}</Alert>
      )}
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        type="text"
        name="firstName"
        label="First name"
        value={formik.values.firstName}
        error={Boolean(formik.touched.firstName && formik.errors.firstName)}
        fullWidth
        helperText={formik.touched.firstName && formik.errors.firstName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        my={1}
      />
      <TextField
        type="text"
        name="lastName"
        label="Last name"
        value={formik.values.lastName}
        error={Boolean(formik.touched.lastName && formik.errors.lastName)}
        fullWidth
        helperText={formik.touched.lastName && formik.errors.lastName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        my={1}
      />
      <TextField
        type="email"
        name="email"
        label="Email address"
        value={formik.values.email}
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        my={1}
      />
      <TextField
        type="password"
        name="password"
        label="Password"
        value={formik.values.password}
        error={Boolean(formik.touched.password && formik.errors.password)}
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        my={1}
      />
      <TextField
        type="password"
        name="confirmPassword"
        label="Confirm password"
        value={formik.values.confirmPassword}
        error={Boolean(
          formik.touched.confirmPassword && formik.errors.confirmPassword,
        )}
        fullWidth
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        my={1}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={formik.isSubmitting}
      >
        Sign up
      </Button>
      <OrDiv>or</OrDiv>
      <Button
        variant="outlined"
        startIcon={<Google />}
        fullWidth
        onClick={async () => {
          try {
            await signInWithGoogle();
            navigate('/');
          } catch (error: any) {
            setError(`Google Sign-in ${firebaseErrorCode(error.code)}`);
          }
        }}
      >
        Coutinue with Google
      </Button>
    </form>
  );
};

export default SignUp;
