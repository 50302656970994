/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getBroadcastsListRequest } from '@api/broadcast';
import BreadCrumbNavigate from '@components/molecules/BreadCrumbNavigate';
import { GridItem, GridSkeleton } from '@components/molecules/GridItem';
import Header from '@components/organisms/Header';
import useAuth from '@hooks/useAuth';
import {
  OrderByProps,
  OsProps,
  OsType,
  ServerNameProps,
  ServerNameType,
  TimeIntervalProps,
  TimestampType,
} from '@usertypes/broadcast';
import {
  getLanguageNameFromCode,
  getRegionNameFromCode,
} from '@utils/utilFunctions';

import {
  Container,
  EmptyData,
  Fab,
  MoreWrapper,
  ProgressWrapper,
} from './Broadcast.style';
import { BroadcastProps } from './Broadcast.type';

const ORDER_BY: OrderByProps = {
  AVERAGE_VIEWS: 'avgViews',
  CREATE_AT: 'createdAt',
};

const SERVER_NAME: ServerNameProps = {
  ALL: 'all',
  FACEBOOK: 'Facebook',
  RTMP: 'rtmp',
  RTSP: 'rtsp',
  SRT: 'srt',
  TWITCH: 'Twitch',
  YOUTUBE: 'Youtube',
  SOOP: 'Soop',
};

const OS_NAME: OsProps = {
  ALL: 'all',
  ANDROID: 'android',
  IOS: 'ios',
};

const TIME_INTERVAL: TimeIntervalProps = {
  ALL: 'all',
  DAY: 'day',
  HOUR: 'hour',
  MONTH: 'month',
  WEEK: 'week',
  YEAR: 'year',
};

const majorCountryCode = [
  'BD',
  'BR',
  'DE',
  'FR',
  'GB',
  'ID',
  'IN',
  'IT',
  'JP',
  'KR',
  'MX',
  'MY',
  'PE',
  'PH',
  'PK',
  'PL',
  'RU',
  'TH',
  'TW',
  'US',
];

const majorLanguageCode = [
  'ar',
  'de',
  'en',
  'es',
  'fr',
  'id',
  'it',
  'ja',
  'ko',
  'pl',
  'pt',
  'ru',
  'th',
  'zh',
];

export const withBroadcast = ({ status, title }: BroadcastProps) => {
  const BroadcastComponent = () => {
    const { token } = useAuth();
    const { ref, inView } = useInView();
    const theme = useTheme();
    const isNotXs = useMediaQuery(theme.breakpoints.not('xs'));

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [clickedId, setClickedId] = useState<number>(0);
    const [{ totalCount, pageCount, currentPage }, setCount] = useState({
      totalCount: 0,
      pageCount: 0,
      currentPage: 0,
    });
    const [showBroadcastListInfos, setShowBroadcastListInfos] = useState<{
      limit: number;
      order: 'avgViews' | 'createdAt';
      page: number;
      serverName: ServerNameType;
      status: string;
      timestamp: TimestampType;
      location: 'all' | string;
      language: 'all' | string;
      os: OsType;
    }>({
      limit: 36,
      order: ORDER_BY.AVERAGE_VIEWS,
      page: 0,
      serverName:
        (sessionStorage.getItem(`${status}_serverName`) as ServerNameType) ||
        SERVER_NAME.ALL,
      status,
      timestamp:
        (sessionStorage.getItem(`${status}_timestamp`) as TimestampType) ||
        TIME_INTERVAL.DAY,
      location: sessionStorage.getItem(`${status}_location`) || 'all',
      language: sessionStorage.getItem(`${status}_language`) || 'all',
      os: OS_NAME.ALL,
    });

    const {
      data,
      fetchNextPage,
      hasNextPage,
      isError,
      isFetching,
      isFetchingNextPage,
      isLoading,
      refetch,
    } = useInfiniteQuery(
      ['broadcasts'],
      async ({ pageParam = 0 }) => {
        const response = await getBroadcastsListRequest(
          token,
          showBroadcastListInfos.limit,
          showBroadcastListInfos.order,
          pageParam,
          showBroadcastListInfos.serverName,
          showBroadcastListInfos.status,
          showBroadcastListInfos.timestamp,
          showBroadcastListInfos.location,
          showBroadcastListInfos.language,
          showBroadcastListInfos.os,
        );
        setCount({
          currentPage: pageParam + 1,
          pageCount: response.pageCount,
          totalCount: response.totalCount,
        });
        return response;
      },
      {
        getNextPageParam: (lastPage) => lastPage.next ?? undefined,
        getPreviousPageParam: (firstPage) => firstPage.prev ?? undefined,
        refetchOnWindowFocus: true,
      },
    );

    useEffect(() => {
      if (inView) {
        fetchNextPage();
      }
    }, [inView, isFetchingNextPage]);

    useEffect(() => {
      refetch();
    }, [showBroadcastListInfos]);

    const initializeBroadcasts = useCallback(() => {
      setCount(() => ({
        currentPage: 0,
        pageCount: 0,
        totalCount: 0,
      }));
    }, []);

    const serverNameHandleChange = useCallback(
      (event: SelectChangeEvent<string>) => {
        initializeBroadcasts();
        const { name, value } = event.target;
        sessionStorage.setItem(`${status}_serverName`, value);
        setShowBroadcastListInfos((prev) => ({
          ...prev,
          page: 0,
          [name]: value,
        }));
      },
      [showBroadcastListInfos],
    );

    const osNameHandleChange = useCallback(
      (event: SelectChangeEvent<OsType>) => {
        initializeBroadcasts();
        const { value } = event.target;
        sessionStorage.setItem(`${status}_os`, value);
        setShowBroadcastListInfos((prev) => ({
          ...prev,
          page: 0,
          os: value as OsType,
        }));
      },
      [showBroadcastListInfos],
    );

    const onClickPopularity = useCallback(() => {
      initializeBroadcasts();
      const showBroadcastListInfoValueToApply = {
        ...showBroadcastListInfos,
        page: 0,
        order: ORDER_BY.AVERAGE_VIEWS,
      };
      setShowBroadcastListInfos(showBroadcastListInfoValueToApply);
    }, [showBroadcastListInfos]);

    const onClickLatest = useCallback(() => {
      initializeBroadcasts();
      const showBroadcastListInfoValueToApply = {
        ...showBroadcastListInfos,
        page: 0,
        order: ORDER_BY.CREATE_AT,
      };
      setShowBroadcastListInfos(showBroadcastListInfoValueToApply);
    }, [showBroadcastListInfos]);

    const timeStampHandleChange = useCallback(
      (event: SelectChangeEvent) => {
        initializeBroadcasts();
        const changeTimestamp = event.target.value as TimestampType;
        sessionStorage.setItem(`${status}_timestamp`, changeTimestamp);
        const showBroadcastListInfoValueToApply = {
          ...showBroadcastListInfos,
          page: 0,
          timestamp: changeTimestamp,
        };
        setShowBroadcastListInfos(showBroadcastListInfoValueToApply);
      },
      [showBroadcastListInfos],
    );

    const locationHandleChange = useCallback(
      (event: SelectChangeEvent) => {
        initializeBroadcasts();
        const changeLocation = event.target.value;
        sessionStorage.setItem(`${status}_location`, changeLocation);
        const showBroadcastListInfoValueToApply = {
          ...showBroadcastListInfos,
          page: 0,
          location: changeLocation,
        };
        setShowBroadcastListInfos(showBroadcastListInfoValueToApply);
      },
      [showBroadcastListInfos],
    );

    const LanguageHandleChange = useCallback(
      (event: SelectChangeEvent) => {
        initializeBroadcasts();
        const changeLanguage = event.target.value;
        sessionStorage.setItem(`${status}_language`, changeLanguage);
        const showBroadcastListInfoValueToApply = {
          ...showBroadcastListInfos,
          page: 0,
          language: changeLanguage,
        };
        setShowBroadcastListInfos(showBroadcastListInfoValueToApply);
      },
      [showBroadcastListInfos],
    );

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
        setAnchorEl(event.currentTarget);
        setClickedId(id);
      },
      [],
    );

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const onClickMoveUpper = useCallback(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, []);

    const toEqualAverageViewsByOrder = useMemo(() => {
      return showBroadcastListInfos.order === ORDER_BY.AVERAGE_VIEWS;
    }, [showBroadcastListInfos.order]);

    const toEqualCreateAtByOrder = useMemo(() => {
      return showBroadcastListInfos.order === ORDER_BY.CREATE_AT;
    }, [showBroadcastListInfos.order]);

    const route = ['Broadcast', title];

    return (
      <>
        <Helmet title={`Broadcast${title}`} />
        <Header
          title={`Broadcast${title} ${totalCount ? `(${totalCount})` : ''}`}
          subTitle={<BreadCrumbNavigate route={route} />}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <ButtonGroup aria-label="order-button-group" size="large">
                <Button
                  variant={
                    toEqualAverageViewsByOrder ? 'contained' : 'outlined'
                  }
                  color={toEqualAverageViewsByOrder ? 'primary' : undefined}
                  onClick={onClickPopularity}
                >
                  Popularity
                </Button>
                <Button
                  variant={toEqualCreateAtByOrder ? 'contained' : 'outlined'}
                  color={toEqualCreateAtByOrder ? 'primary' : undefined}
                  onClick={onClickLatest}
                >
                  Latest
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item>
              <FormControl variant="outlined">
                <InputLabel id="os-select-outlined-label">OS</InputLabel>
                <Select
                  labelId="os-select-outlined-label"
                  id="so-select-outlined"
                  value={showBroadcastListInfos.os}
                  onChange={osNameHandleChange}
                  label="OS"
                  defaultValue={'all'}
                >
                  <MenuItem value={OS_NAME.ALL}>{OS_NAME.ALL}</MenuItem>
                  <MenuItem value={OS_NAME.ANDROID}>{OS_NAME.ANDROID}</MenuItem>
                  <MenuItem value={OS_NAME.IOS}>{OS_NAME.IOS}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined">
                <InputLabel id="server-select-outlined-label">
                  Server
                </InputLabel>
                <Select
                  labelId="server-select-outlined-label"
                  id="server-select-outlined"
                  value={showBroadcastListInfos.serverName}
                  onChange={serverNameHandleChange}
                  label="Server"
                  name="serverName"
                >
                  <MenuItem value={SERVER_NAME.ALL}>
                    {SERVER_NAME.ALL.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={SERVER_NAME.YOUTUBE}>
                    {SERVER_NAME.YOUTUBE}
                  </MenuItem>
                  <MenuItem value={SERVER_NAME.FACEBOOK}>
                    {SERVER_NAME.FACEBOOK}
                  </MenuItem>
                  <MenuItem value={SERVER_NAME.TWITCH}>
                    {SERVER_NAME.TWITCH}
                  </MenuItem>
                  <MenuItem value={SERVER_NAME.RTMP}>
                    {SERVER_NAME.RTMP.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={SERVER_NAME.RTSP}>
                    {SERVER_NAME.RTSP.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={SERVER_NAME.SRT}>
                    {SERVER_NAME.SRT.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={SERVER_NAME.SOOP}>
                    {SERVER_NAME.SOOP.toUpperCase()}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined">
                <InputLabel id="time-select-outlined-label">Time</InputLabel>
                <Select
                  labelId="time-select-outlined-label"
                  id="time-select-outlined"
                  value={showBroadcastListInfos.timestamp}
                  onChange={timeStampHandleChange}
                  label="Time"
                  defaultValue={TIME_INTERVAL.ALL}
                >
                  <MenuItem value={TIME_INTERVAL.ALL}>
                    {TIME_INTERVAL.ALL.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={TIME_INTERVAL.HOUR}>
                    {TIME_INTERVAL.HOUR.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={TIME_INTERVAL.DAY}>
                    {TIME_INTERVAL.DAY.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={TIME_INTERVAL.WEEK}>
                    {TIME_INTERVAL.WEEK.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={TIME_INTERVAL.MONTH}>
                    {TIME_INTERVAL.MONTH.toUpperCase()}
                  </MenuItem>
                  <MenuItem value={TIME_INTERVAL.YEAR}>
                    {TIME_INTERVAL.YEAR.toUpperCase()}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined">
                <InputLabel id="location-select-outlined-label">
                  Location
                </InputLabel>
                <Select
                  labelId="location-select-outlined-label"
                  id="location-select-outlined"
                  value={showBroadcastListInfos.location}
                  onChange={locationHandleChange}
                  label="Location"
                  defaultValue={'all'}
                >
                  <MenuItem value="all">ALL</MenuItem>
                  {majorCountryCode.map((countryCode) => (
                    <MenuItem value={countryCode} key={countryCode}>
                      {getRegionNameFromCode(countryCode)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="outlined">
                <InputLabel id="language-select-outlined-label">
                  Language
                </InputLabel>
                <Select
                  labelId="language-select-outlined-label"
                  id="language-select-outlined"
                  value={showBroadcastListInfos.language}
                  onChange={LanguageHandleChange}
                  label="Language"
                  defaultValue={'all'}
                >
                  <MenuItem value="all">ALL</MenuItem>
                  {majorLanguageCode.map((languageCode) => (
                    <MenuItem value={languageCode} key={languageCode}>
                      {getLanguageNameFromCode(languageCode)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Header>

        {isLoading ? (
          <Grid container spacing={isNotXs ? 6 : 0}>
            {[...Array(36)].map((_, index) => (
              <GridSkeleton key={index} />
            ))}
          </Grid>
        ) : isError ? (
          <EmptyData>현재 방송의 데이터가 존재하지 않습니다.</EmptyData>
        ) : (
          <>
            <Container>
              <Grid container spacing={isNotXs ? 6 : 0}>
                {data.pages.map((page) => (
                  <Fragment key={page.next}>
                    {page.broadcasts.map((broadcast) => (
                      <GridItem
                        anchorEl={anchorEl}
                        broadcast={broadcast}
                        clickedId={clickedId}
                        handleClick={handleClick}
                        handleClose={handleClose}
                        key={broadcast.id}
                      />
                    ))}
                  </Fragment>
                ))}
                {isFetching &&
                  hasNextPage &&
                  [...Array(36)].map((_, index) => (
                    <GridSkeleton key={index} />
                  ))}
              </Grid>
            </Container>
            <Fab variant="extended" onClick={onClickMoveUpper}>
              <KeyboardArrowUpIcon />{' '}
              {`${
                currentPage < pageCount ? 36 * currentPage : totalCount
              } / ${totalCount}`}
            </Fab>
          </>
        )}

        <MoreWrapper ref={ref}>
          {isFetchingNextPage ? (
            <ProgressWrapper>
              <CircularProgress />
            </ProgressWrapper>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage}
            >
              {hasNextPage ? 'Load Newer' : 'Nothing more to load'}
            </Button>
          )}
        </MoreWrapper>
      </>
    );
  };

  return BroadcastComponent;
};

export default withBroadcast;
