import {
  Checkbox,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { TableHeadProps } from './TableHead.type';

const TableHead = ({
  headCells,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  rowCount,
}: TableHeadProps) => {
  const createSortHandler =
    (id: string) => (event: React.MouseEvent<unknown>) => {
      if (onRequestSort) onRequestSort(event, id);
    };

  return (
    <MuiTableHead>
      <TableRow>
        <TableCell />
        {numSelected !== undefined && onSelectAllClick && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon={!headCell.sortable}
              active={headCell.sortable && orderBy === headCell.id}
              direction={
                headCell.sortable && orderBy === headCell.id ? order : 'asc'
              }
              onClick={
                headCell.sortable ? createSortHandler(headCell.id) : undefined
              }
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
