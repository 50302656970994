import { Alert, TextField, styled } from '@mui/material';

export const StyledAlert = styled(Alert)`
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 12px;
  margin-bottom: 12px;
`;
