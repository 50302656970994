import { useState, ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import dashboardItems from '@components/organisms/Sidebar/dashboardItems';
import Navbar from '@components/organisms/Navbar/Navbar';
import Sidebar from '@components/organisms/Sidebar/Sidebar';
import AuthGuard from '@components/templates/AuthGuard';

import { Root, Drawer, AppContent, MainContent } from './DashboardLayout.style';

const DashboardLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isNotXs = useMediaQuery(theme.breakpoints.not('xs'));
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  const drawerWidth = 258;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AuthGuard>
      <Root>
        <Drawer>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItems}
              pathname={location.pathname}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              items={dashboardItems}
              pathname={location.pathname}
            />
          </Box>
        </Drawer>
        <AppContent>
          <Navbar onDrawerToggle={handleDrawerToggle} />
          <MainContent p={isLgUp ? 12 : isNotXs ? 5 : 2}>
            {children}
            <Outlet />
          </MainContent>
        </AppContent>
      </Root>
    </AuthGuard>
  );
};

export default DashboardLayout;
