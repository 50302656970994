import { Typography } from '@mui/material';

import { Breadcrumbs } from '@components/atoms/Spacing';

import { BreadCrumbNavigateProps } from './BreadCrumbNavigate.type';

const TableList = ({ route }: BreadCrumbNavigateProps) => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      {route.map((item, index) => (
        <Typography key={index}>{item}</Typography>
      ))}
    </Breadcrumbs>
  );
};

export default TableList;
