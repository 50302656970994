import { useNavigate } from 'react-router-dom';
import { Drawer, ListItemButton, styled } from '@mui/material';

import Logo from '@components/atoms/Icons/Logo';
import { SidebarItemsType } from '@usertypes/sidebar';

import Footer from './SidebarFooter';
import SidebarNav from './SidebarNav';

const Brand = styled(ListItemButton)`
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  flex-grow: 0;
  justify-content: center;
  min-height: 56px;
  padding-left: ${({ theme }) => theme.spacing(6)};
  padding-right: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-height: 64px;
  }
`;

const Sidebar: React.FC<{
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: 'permanent' | 'persistent' | 'temporary';
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
  pathname: string;
}> = ({ items, showFooter = true, pathname, ...rest }) => {
  const navigate = useNavigate();

  return (
    <Drawer
      variant="permanent"
      sx={{
        borderRight: 0,

        '> div': {
          borderRight: 0,
        },
      }}
      {...rest}
    >
      <Brand
        onClick={() => {
          navigate('/dashboard');
        }}
      >
        <Logo />
      </Brand>
      <SidebarNav items={items} pathname={pathname} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
