import { SnackbarProvider } from 'notistack';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from '@contexts/AuthContext';
import reportWebVitals from '@utils/reportWebVitals';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <BrowserRouter>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | CameraFi Admin"
          defaultTitle="CameraFi Admin"
        />
        <AuthProvider>
          <App />
        </AuthProvider>
      </HelmetProvider>
    </BrowserRouter>
  </SnackbarProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
