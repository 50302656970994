import { useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { getUserRequest } from '@api/user';
import useAuth from '@hooks/useAuth';
import { errorMessage } from '@utils/utilFunctions';

const GuestGuard = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, signOut, token, user } = useAuth();
  const navigate = useNavigate();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const [unauthorized, setUnauthorized] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut();
      const key = enqueueSnackbar('성공적으로 로그아웃 되었습니다.', {
        onClick: () => closeSnackbar(key),
        variant: 'success',
      });
    } catch (error) {
      const key = enqueueSnackbar(errorMessage(error), {
        onClick: () => closeSnackbar(key),
        variant: 'error',
      });
    }
  };

  const authCheck = async () => {
    if (isAuthenticated === undefined) {
      setUnauthorized(false);
    } else if (isAuthenticated === false) {
      setUnauthorized(true);
    } else {
      try {
        const serverUser = await getUserRequest(token, user?.id);
        if (serverUser.role === 'role-admin') {
          setUnauthorized(false);
          navigate('/dashboard');
        } else {
          setUnauthorized(true);
          handleSignOut();
        }
      } catch (error) {
        setUnauthorized(true);
        handleSignOut();
      }
    }
  };

  useEffect(() => {
    authCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return unauthorized ? <>{children}</> : <></>;
};

export default GuestGuard;
