import { useSnackbar } from 'notistack';
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { ExitToApp as ExitToAppIcon } from '@mui/icons-material';

import useAuth from '@hooks/useAuth';
import { errorMessage } from '@utils/utilFunctions';

const Footer = styled('div')`
  background-color: ${({ theme }) =>
    theme.palette.background.sidebar} !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacing(2.75)} ${theme.spacing(4)}`};
`;

const FooterText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const FooterSubText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  span {
    background-color: ${({ theme }) => theme.palette.success.main};
    border: 1.5px solid ${({ theme }) => theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const Profile = styled('div')`
  display: flex;
`;

const ProfileContent = styled('div')`
  margin-left: 10px;
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const { user, signOut } = useAuth();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const handleSignOut = async () => {
    try {
      await signOut();
      const key = enqueueSnackbar('성공적으로 로그아웃 되었습니다.', {
        onClick: () => closeSnackbar(key),
        variant: 'success',
      });
    } catch (error) {
      const key = enqueueSnackbar(errorMessage(error), {
        onClick: () => closeSnackbar(key),
        variant: 'error',
      });
    }
  };

  return (
    <Footer {...rest}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <Profile>
            {!!user && (
              <FooterBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar alt={user.displayName} src={user.photoURL} />
              </FooterBadge>
            )}
            <ProfileContent>
              {!!user && (
                <FooterText variant="body2">{user.displayName}</FooterText>
              )}
              {!!user && (
                <FooterSubText variant="caption">{user.role}</FooterSubText>
              )}
            </ProfileContent>
          </Profile>
        </Grid>
        <Grid item>
          <IconButton onClick={handleSignOut}>
            <ExitToAppIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
