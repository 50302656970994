import { SvgIcon, SvgIconProps } from '@mui/material';

const AndroidMockupBackground = (props: SvgIconProps) => (
  <SvgIcon sx={{ height: 382, width: 320 }} viewBox="0 0 320 382" {...props}>
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="320"
      height="382"
    >
      <rect width="320" height="382" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M315.4 238.4H317.6C318.705 238.4 319.6 239.295 319.6 240.4V330C319.6 331.105 318.705 332 317.6 332H315.4V238.4Z"
        stroke="#C0C0CC"
        strokeWidth="0.8"
      />
      <path
        d="M315.4 146H317.6C318.705 146 319.6 146.895 319.6 148V191.2C319.6 192.305 318.705 193.2 317.6 193.2H315.4V146Z"
        stroke="#C0C0CC"
        strokeWidth="0.8"
      />
      <rect
        x="0.4"
        y="0.4"
        width="315.2"
        height="688.2"
        rx="38"
        stroke="#C0C0CC"
        strokeWidth="0.8"
      />
      <rect
        x="4.4"
        y="4.4"
        width="307.2"
        height="679.2"
        rx="34.6"
        stroke="#C0C0CC"
        strokeWidth="0.8"
      />
      <rect
        x="14"
        y="51"
        width="288"
        height="592"
        rx="12.8"
        fill="#F5F5F7"
        stroke="#C0C0CC"
      />
      <rect y="302" width="320" height="80" fill="url(#paint0_linear)" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="0"
        y1="302"
        x2="0"
        y2="382"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.01" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default AndroidMockupBackground;
