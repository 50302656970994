import { useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Img } from './ImgItem.style';
import type { ImgItemProps } from './ImgItem.type';

const ImgItem = ({
  img,
  isShowThumbnailImg,
  thumbnailImg,
  type = 'match',
  deleteImg,
  updateThumbnailImg,
}: ImgItemProps) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [isShowDeleteBtn, setIsShowDeleteBtn] = useState(false);

  const isThumbnail = useMemo(
    () => thumbnailImg === img.fileName && isShowThumbnailImg,
    [img.fileName, thumbnailImg, isShowThumbnailImg],
  );

  return (
    <Grid
      item
      sx={({ palette: { mode } }) => ({
        aspectRatio: type === 'match' ? '3 / 2' : '3 / 4',
        boxSizing: 'border-box',
        border: `${
          isThumbnail
            ? mode === 'light' && type === 'event'
              ? '3px solid #37C556'
              : '3px solid white'
            : '0.01px solid transparent'
        }`,
        flexGrow: 1,
        position: 'relative',
        transition: 'border 0.3s',
        opacity: `${isThumbnail ? '1' : '0.5'}`,
        minWidth: type === 'event' ? '140px' : undefined,
        width: {
          xs: 'calc((100% - 12px) / 2)',
          sm: 'calc((100% - 24px) / 3)',
          md: 'calc((100% - 36px) / 4)',
        },
        maxWidth: {
          xs: 'calc((100% - 12px) / 2)',
          sm: 'calc((100% - 24px) / 3)',
          md: 'calc((100% - 36px) / 4)',
        },
      })}
      onMouseEnter={() => setIsMouseEnter(true)}
      onMouseLeave={() => setIsMouseEnter(false)}
    >
      <Img
        src={img.url}
        alt={img.fileName}
        isMouseEnter={isMouseEnter && !isThumbnail}
        onClick={(e) => {
          e.stopPropagation();
          if (isShowThumbnailImg) updateThumbnailImg(img.fileName);
        }}
      />
      {deleteImg ? (
        <MoreVertIcon
          sx={{
            position: 'absolute',
            top: '0px',
            right: '4px',
            color: 'gray.80',
            width: '32px',
            height: '32px',
            boxSizing: 'border-box',
            padding: '4px',
          }}
          onMouseEnter={() => setIsShowDeleteBtn(true)}
          onMouseLeave={() => setIsShowDeleteBtn(false)}
        />
      ) : null}

      {isShowDeleteBtn ? (
        <Box
          sx={{
            backgroundColor: 'gray.99',
            borderRadius: '8px',
            cursor: 'pointer',
            padding: 1,
            position: 'absolute',
            right: '0px',
            top: '30px',
            zIndex: 1,
            '&:hover': {
              backgroundColor: 'gray.90',
            },
          }}
          onClick={() => {
            if (deleteImg) deleteImg(img);
            setIsShowDeleteBtn(false);
          }}
          onMouseEnter={() => setIsShowDeleteBtn(true)}
          onMouseLeave={() => setIsShowDeleteBtn(false)}
        >
          <Typography variant="body2" color="gray.10">
            삭제
          </Typography>
        </Box>
      ) : null}
      <CheckCircleIcon
        sx={{
          bottom: '5px',
          color: '#D9D9D9',
          opacity: `${isThumbnail ? '1' : '0'}`,
          position: 'absolute',
          right: '5px',
          transition: 'opacity 0.3s',
        }}
      />
    </Grid>
  );
};

export default ImgItem;
