import { Paper, styled } from '@mui/material';

export const BrandWrapper = styled('div')`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
`;

export const Wrapper = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 520px;
  min-height: 100%;
`;

export const StyledPaper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
