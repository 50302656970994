import { SvgIcon, SvgIconProps } from '@mui/material';

const Facebook = (props: SvgIconProps) => (
  <SvgIcon sx={{ height: 36, width: 36 }} viewBox="0 0 32 32" {...props}>
    <path
      d="M29.333 15.93c0-7.326-5.97-13.263-13.333-13.263-7.364 0-13.333 5.937-13.333 13.262 0 6.62 4.875 12.106 11.25 13.101v-9.267H10.53v-3.834h3.386v-2.922c0-3.324 1.99-5.16 5.036-5.16 1.458 0 2.985.26 2.985.26v3.263h-1.682c-1.656 0-2.173 1.023-2.173 2.072v2.487h3.698l-.59 3.834h-3.108v9.267c6.375-.995 11.25-6.481 11.25-13.1Z"
      fill="#1877F2"
    />
    <path
      d="m21.19 19.763.591-3.834h-3.697v-2.487c0-1.048.516-2.072 2.172-2.072h1.682V8.106s-1.526-.259-2.985-.259c-3.046 0-5.036 1.836-5.036 5.16v2.922H10.53v3.834h3.386v9.267c1.38.215 2.786.215 4.167 0v-9.267h3.106Z"
      fill="#fff"
    />
  </SvgIcon>
);

export default Facebook;
