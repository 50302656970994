import { FirebaseError } from 'firebase/app';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { object, string } from 'yup';
import { Button } from '@mui/material';

import useAuth from '@hooks/useAuth';
import { errorMessage } from '@utils/utilFunctions';
import { firebaseErrorCode } from '@utils/firebaseErrorCode';

import { StyledAlert, StyledTextField } from './ResetPassword.style';

const ResetPassword = () => {
  const { resetPassword } = useAuth();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      email: '',
      submit: false,
    },
    validationSchema: object().shape({
      email: string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        await resetPassword(values.email);
        const key = enqueueSnackbar(
          '비밀번호 변경 링크가 이메일로 전송되었습니다.',
          {
            onClick: () => closeSnackbar(key),
            variant: 'info',
          },
        );
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          const message = firebaseErrorCode(error.code);
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
        const key = enqueueSnackbar(errorMessage(error), {
          onClick: () => closeSnackbar(key),
          variant: 'error',
        });
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      {formik.errors.submit && (
        <StyledAlert severity="error">{formik.errors.submit}</StyledAlert>
      )}
      <StyledTextField
        type="email"
        name="email"
        label="Email Address"
        value={formik.values.email}
        error={Boolean(formik.touched.email && formik.errors.email)}
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={formik.isSubmitting}
      >
        Reset password
      </Button>
    </form>
  );
};

export default ResetPassword;
