import { Box } from '@mui/material';
import { useRef } from 'react';
import SunEditor from 'suneditor-react';
import {
  UploadBeforeHandler,
  UploadBeforeReturn,
  UploadInfo,
} from 'suneditor-react/dist/types/upload';
import SunEditorType from 'suneditor/src/lib/core';

const Editor = ({
  handleBlurEditor,
  handleImageUploadBefore,
  handleImageUpload,
  value,
}: {
  handleBlurEditor: (event: FocusEvent, editorContents: string) => void;
  handleImageUploadBefore: (
    files: File[],
    _info: object,
    uploadHandler: UploadBeforeHandler,
  ) => UploadBeforeReturn;
  handleImageUpload: (
    _targetImgElement: HTMLImageElement,
    index: number,
    state: 'create' | 'update' | 'delete',
    imageInfo: UploadInfo<HTMLImageElement>,
  ) => void;
  value?: string;
}) => {
  const tmpRef = useRef<SunEditorType | null>(null);

  return (
    <Box
      sx={{
        mb: 1,
        '& .sun-editor': {
          bgcolor: 'background.default',
          borderRadius: 1,
        },
        '& .sun-editor .se-container .se-toolbar .se-submenu button': {
          color: 'black',
          '&:hover': {
            bgcolor: 'primary.light',
          },
        },
        '& .sun-editor .se-container .se-toolbar': {
          bgcolor: '#ffffff',
          textAlign: { xs: 'left', md: 'center' },
          border: 'black',
          borderRadius: '4px 4px 0px 0px',
          '& button:hover': {
            bgcolor: '#ededed',
          },
        },
        '& .sun-editor .se-container .se-wrapper-inner': {
          border: 'black',
          outline: 'black',
        },
        '& .sun-editor .se-container .se-wrapper .se-placeholder': {
          fontSize: '16px',
        },
        '& .sun-editor .se-container .se-resizing-bar': {
          display: 'none',
        },
        '& .sun-editor .se-controller ._se_resizing_btn_group': {
          display: 'none',
        },
        '& .sun-editor .se-controller .se-btn-group [data-command="mirror"]': {
          display: 'none',
        },
      }}
    >
      <SunEditor
        setContents={value}
        placeholder="이벤트 설명을 입력하세요."
        lang="ko"
        height="400px"
        getSunEditorInstance={(editor: SunEditorType) => {
          tmpRef.current = editor;
        }}
        onBlur={handleBlurEditor}
        onImageUploadBefore={handleImageUploadBefore}
        onImageUpload={handleImageUpload}
        setOptions={{
          imageRotation: false,
          imageUrlInput: false,
          defaultStyle: 'font-size:16px',
          mediaAutoSelect: false,
          buttonList: [
            [
              'undo',
              'redo',
              'formatBlock',
              'fontSize',
              'bold',
              'underline',
              'italic',
              'strike',
              'fontColor',
              'hiliteColor',
              'removeFormat',
            ],
            [
              'link',
              'image',
              'outdent',
              'indent',
              'align',
              'list',
              'horizontalRule',
            ],
          ],
        }}
      />
    </Box>
  );
};

export default Editor;
