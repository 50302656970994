import { useEffect, useState } from 'react';
import { upperFirst } from 'lodash';
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useQuery } from '@tanstack/react-query';
import * as iso3311a2 from 'iso-3166-1-alpha-2';

import { getShopifyEventsList } from '@api/shopify';
import SearchBar from '@components/molecules/SearchBar';
import Header from '@components/organisms/Header';
import ResponsiveTable from '@components/organisms/ResponsiveTable';
import useAuth from '@hooks/useAuth';
import useShoppingEventQuery from '@hooks/useShoppingQuery';
import { EventInfo, EventListParams } from '@usertypes/shopify';
import { dayformatter } from '@utils/utilFunctions';

const EventList = () => {
  const { token } = useAuth();

  const {
    country,
    endTimeFilterEnd,
    endTimeFilterStart,
    eventTitleFilter,
    order,
    referenceNumberFilter,
    setCountry,
    setEndTimeFilterEnd,
    setEndTimeFilterStart,
    setEventTitleFilter,
    setOrder,
    setReferenceNumberFilter,
    setShopNameFilter,
    setSortType,
    setStartTimeFilterEnd,
    setStartTimeFilterStart,
    setStatus,
    setType,
    shopNameFilter,
    sortType,
    startTimeFilterEnd,
    startTimeFilterStart,
    status,
    type,
  } = useShoppingEventQuery();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const typeFilterArr = [
    'live',
    'vod',
    'all',
  ] as EventListParams['typeFilter'][] & 'all'[];

  const statusFilterArr = [
    'live',
    'scheduled',
    'rehearsal',
    'finished',
    'all',
  ] as EventListParams['statusFilter'][] & 'all'[];

  const sortTypeArr: EventListParams['sortType'][] = [
    'viewers',
    'totalSalesAmountUSD',
    'orderCountDuringLive',
  ] as EventListParams['sortType'][] & 'all'[];

  const orderArr: EventListParams['order'][] = ['DESC', 'ASC'];

  const {
    data: { events, totalCount } = {
      events: [],
      pageCount: 0,
      totalCount: 0,
    },
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['shopifyEventList'],
    () =>
      getShopifyEventsList({
        token,
        filters: {
          order,
          page: `${page}`,
          limit: `${limit}`,
          statusFilter: status !== 'all' ? status : undefined,
          typeFilter: type !== 'all' ? type : undefined,
          sortType,
          shopNameFilter,
          eventTitleFilter,
          referenceNumberFilter,
          countryFilter: country?.code,
          startTimeFilterStart: startTimeFilterStart
            ? dayformatter(startTimeFilterStart)
            : undefined,
          startTimeFilterEnd: startTimeFilterEnd
            ? dayformatter(startTimeFilterEnd)
            : undefined,
          endTimeFilterStart: endTimeFilterStart
            ? dayformatter(endTimeFilterStart)
            : undefined,
          endTimeFilterEnd: endTimeFilterEnd
            ? dayformatter(endTimeFilterEnd)
            : undefined,
        },
      }),
    {
      enabled: !!token,
    },
  );

  useEffect(() => {
    refetch();
  }, [
    country,
    endTimeFilterEnd,
    endTimeFilterStart,
    eventTitleFilter,
    eventTitleFilter,
    limit,
    order,
    page,
    referenceNumberFilter,
    refetch,
    shopNameFilter,
    sortType,
    startTimeFilterEnd,
    startTimeFilterStart,
    status,
    type,
  ]);

  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'shortOffset',
  };

  const createRowItemList = (event: EventInfo) => [
    <Typography>{event.referenceNumber}</Typography>,
    <img
      src={event.thumbnailUrl}
      width={40}
      height={40}
      alt="event_thumbnail"
    />,
    <Typography>{event.title}</Typography>,
    <Typography>{event.type}</Typography>,
    <Typography>{event.eventStatus}</Typography>,
    <Typography>
      {new Intl.DateTimeFormat('ko', options).format(
        new Date(event.scheduledStartTime),
      )}
    </Typography>,
    <Typography>
      {event.actualStartTime
        ? new Intl.DateTimeFormat('ko', options).format(
            new Date(event.actualStartTime),
          )
        : 'X'}
    </Typography>,
    <Typography>
      {event.actualEndTime
        ? new Intl.DateTimeFormat('ko', options).format(
            new Date(event.actualEndTime),
          )
        : 'X'}
    </Typography>,
  ];

  const createDefaultDetailItemList = (event: EventInfo) => {
    const regionNames = new Intl.DisplayNames(['kr'], {
      type: 'region',
    });
    return [
      {
        TotalSalesAmount: <Typography>{event.totalSalesAmountUSD}$</Typography>,
      },
      {
        Country: <Typography>{regionNames.of(event.country)}</Typography>,
      },
      { Billed: <Typography>{event.billed ? 'O' : 'X'}</Typography> },
      { Viewers: <Typography>{event.viewers}</Typography> },
      {
        ShopName: (
          <Link href={`https://${event.shopName}`}>
            <Typography>{event.shopName}</Typography>
          </Link>
        ),
      },
      {
        ViewPageUrl: (
          <Link href={event.viewPageUrl}>
            <Typography>{event.viewPageUrl.split('https://')[1]}</Typography>
          </Link>
        ),
      },
    ];
  };

  const createDetailItemList = (event: EventInfo) => [
    {
      ScheduledStartTime: new Intl.DateTimeFormat('ko', options).format(
        new Date(event.scheduledStartTime),
      ),
    },
    {
      ActualStartTime: event.actualStartTime
        ? new Intl.DateTimeFormat('ko', options).format(
            new Date(event.actualStartTime),
          )
        : 'X',
    },
    {
      ActualEndTime: event.actualEndTime
        ? new Intl.DateTimeFormat('ko', options).format(
            new Date(event.actualEndTime),
          )
        : 'X',
    },
  ];

  const eventHeadCells = [
    { id: 'eventId', label: 'EventID' },
    { id: 'thumbnailUrl', label: 'Thumbnail' },
    { id: 'title', label: 'Title' },
    { id: 'type', label: 'Type' },
    { id: 'eventStatus', label: 'Status' },
    { id: 'scheduledStartTime', label: 'Scheduled Start Time' },
    { id: 'actualStartTimeFilter', label: 'Actual Start Time' },
    { id: 'actualEndTimeFilter', label: 'Actual End Time' },
  ];

  const handleStatusChange = (
    event: SelectChangeEvent<EventListParams['statusFilter'] | 'all'>,
  ) => {
    const changeSort = event.target.value as
      | EventListParams['statusFilter']
      | 'all';
    setStatus(changeSort);
  };

  const handleSortTypeChange = (
    event: SelectChangeEvent<EventListParams['sortType']>,
  ) => {
    const changeSort = event.target.value as EventListParams['sortType'];
    setSortType(changeSort);
  };

  const handleTypeChange = (
    event: SelectChangeEvent<EventListParams['typeFilter'] | 'all'>,
  ) => {
    const newType = event.target.value as EventListParams['typeFilter'] | 'all';
    setType(newType);
  };

  const handleOrderChange = (
    event: SelectChangeEvent<EventListParams['order']>,
  ) => {
    const newOrder = event.target.value as EventListParams['order'];
    setOrder(newOrder);
  };

  const idExtractor = (shopInfo: EventInfo) => `${shopInfo.id}`;

  const countriesData = iso3311a2.getData();

  const objectKeys = Object.keys(countriesData);
  objectKeys.sort();
  const sortedCountriesList = objectKeys.map((key) => ({
    code: key,
    label: countriesData[key] || 'unknown',
  }));

  return (
    <>
      <Header title="Camerafi Shopping" subTitle="Camerafi Shopping / Events">
        <Grid container spacing={2}>
          <Grid item>
            <Box sx={{ display: 'flex' }}>
              <SearchBar
                target="ShopName"
                value={shopNameFilter}
                placeholder="ShopName Search"
                onChange={(newShopName: string) =>
                  setShopNameFilter(newShopName)
                }
                onCancelSearch={() => {
                  setShopNameFilter('');
                }}
                onRequestSearch={() => {}}
              />
              <SearchBar
                target="EventTitle"
                value={eventTitleFilter}
                placeholder="EventTitle Search"
                onChange={(newEventTitle: string) =>
                  setEventTitleFilter(newEventTitle)
                }
                onCancelSearch={() => {
                  setEventTitleFilter('');
                }}
                onRequestSearch={() => {}}
              />
              <SearchBar
                target="ReferenceNumber"
                value={referenceNumberFilter}
                placeholder="ReferenceNumber Search"
                onChange={(newReferenceNumber: string) =>
                  setReferenceNumberFilter(newReferenceNumber)
                }
                onCancelSearch={() => {
                  setReferenceNumberFilter('');
                }}
                onRequestSearch={() => {}}
              />
            </Box>
          </Grid>
          <Grid item sx={{ display: 'flex', gap: 2 }}>
            <FormControl variant="outlined">
              <InputLabel id="sort-select-outlined-label">Order</InputLabel>
              <Select
                defaultValue="DESC"
                id="sort-select-outlined"
                label="status"
                labelId="sort-select-outlined-label"
                onChange={handleOrderChange}
                sx={{ width: '160px', ml: 2 }}
                value={order}
              >
                {orderArr.map((sort) => (
                  <MenuItem value={sort} key={sort}>
                    {upperFirst(sort)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="sort-select-outlined-label">Type</InputLabel>
              <Select
                defaultValue="all"
                id="sort-select-outlined"
                label="status"
                labelId="sort-select-outlined-label"
                onChange={handleTypeChange}
                sx={{ width: '160px', ml: 2 }}
                value={type}
              >
                {typeFilterArr.map((sort) => (
                  <MenuItem value={sort} key={sort}>
                    {upperFirst(sort)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="sort-select-outlined-label">Status</InputLabel>
              <Select
                defaultValue="live"
                id="sort-select-outlined"
                label="sort"
                labelId="sort-select-outlined-label"
                onChange={handleStatusChange}
                sx={{ width: '160px', ml: 2 }}
                value={status}
              >
                {statusFilterArr.map((sort) => (
                  <MenuItem value={sort} key={sort}>
                    {upperFirst(sort)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="sort-select-outlined-label">Sort Type</InputLabel>
              <Select
                defaultValue="viewers"
                id="sort-select-outlined"
                label="sort"
                labelId="sort-select-outlined-label"
                onChange={handleSortTypeChange}
                sx={{ width: '160px', ml: 2 }}
                value={sortType}
              >
                {sortTypeArr.map((sort) => (
                  <MenuItem value={sort} key={sort}>
                    {upperFirst(sort)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined">
              <InputLabel id="sort-select-outlined-label">Country</InputLabel>
            </FormControl>
            <Autocomplete
              freeSolo
              value={country}
              disablePortal
              id="combo-box-demo"
              options={sortedCountriesList}
              sx={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label="Country" />
              )}
              onChange={(_: any, newValue) => {
                if (newValue && typeof newValue === 'object')
                  setCountry(newValue);
                else setCountry(null);
              }}
            />
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <DateTimePicker
                  label="startTimeFilterStart"
                  value={startTimeFilterStart}
                  onChange={(newValue) => setStartTimeFilterStart(newValue)}
                  renderInput={(props) => <TextField {...props} />}
                />
                ~
                <DateTimePicker
                  label="startTimeFilterEnd"
                  value={startTimeFilterEnd}
                  onChange={(newValue) => setStartTimeFilterEnd(newValue)}
                  renderInput={(props) => <TextField {...props} />}
                />
                /
                <DateTimePicker
                  label="endTimeFilterStart"
                  value={endTimeFilterStart}
                  onChange={(newValue) => setEndTimeFilterStart(newValue)}
                  renderInput={(props) => <TextField {...props} />}
                />
                ~
                <DateTimePicker
                  label="endTimeFilterEnd"
                  value={endTimeFilterEnd}
                  onChange={(newValue) => setEndTimeFilterEnd(newValue)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Box>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Header>
      <ResponsiveTable
        datas={events}
        handleChangePage={(newPage: number) => setPage(newPage + 1)}
        handleChangeRowsPerPage={(newLimit: string) =>
          setLimit(parseInt(newLimit, 10))
        }
        handleRefresh={refetch}
        handleRowItemList={createRowItemList}
        handleDetailItemList={createDetailItemList}
        headCells={eventHeadCells}
        idExtractor={idExtractor}
        isFetching={isFetching}
        isLoading={isLoading}
        limit={limit}
        page={page - 1}
        skeletonRowHeight={89}
        tableName="Shop List"
        totalCount={Number(totalCount)}
        handleDefaultDetailItemList={createDefaultDetailItemList}
        responsive
      />
    </>
  );
};

export default EventList;
