import { SvgIcon, SvgIconProps } from '@mui/material';

const Twitch = (props: SvgIconProps) => (
  <SvgIcon sx={{ height: 36, width: 36 }} viewBox="0 0 32 32" {...props}>
    <path
      d="m25.718 16.38-3.828 3.81h-3.827l-3.35 3.334V20.19h-4.305V5.905h15.31V16.38Z"
      fill="#fff"
    />
    <path
      d="M9.451 4 4.667 8.762v17.143h5.74v4.762l4.785-4.762h3.828l8.612-8.572V4H9.452Zm16.267 12.381-3.828 3.81h-3.827l-3.35 3.333V20.19h-4.305V5.905h15.31V16.38Z"
      fill="#9146FF"
    />
    <path
      d="M22.847 9.238h-1.914v5.714h1.914V9.238ZM17.584 9.238h-1.913v5.714h1.913V9.238Z"
      fill="#9146FF"
    />
  </SvgIcon>
);

export default Twitch;
