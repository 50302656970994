/* eslint-disable react-hooks/exhaustive-deps */
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';

import { SearchBarProps, Ref } from './SearchBar.type';
import {
  ClearIconWrapper,
  Input,
  Search,
  SearchFilter,
  SearchIconWrapper,
} from './SearchBar.style';

const SearchBar = forwardRef<Ref, SearchBarProps>(
  (
    {
      onBlur,
      onCancelSearch,
      onChange,
      onFocus,
      onKeyUp,
      onRequestSearch,
      placeholder,
      target,
      value = '',
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(value);
    const [searchFilterOn, setSearchFilterOn] = useState(false);
    const [touched, setTouched] = useState(false);
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) {
          onFocus(e);
        }
      },
      [onFocus],
    );

    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        setInputValue((v) => v.trim());
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur],
    );

    const handleInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (touched === false) {
          setTouched(true);
        }
        setInputValue(e.target.value);
        if (onChange) {
          onChange(e.target.value);
        }
      },
      [onChange],
    );

    const handleCancel = useCallback(() => {
      setInputValue('');
      if (onCancelSearch) {
        onCancelSearch();
        setSearchFilterOn(false);
      }
    }, [onCancelSearch]);

    const handleRequestSearch = useCallback(() => {
      if (onRequestSearch) {
        onRequestSearch(inputValue);
        if (inputValue.length >= 2) setSearchFilterOn(true);
      }
    }, [onRequestSearch, value]);

    const handleKeyUp = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          handleRequestSearch();
        } else if (e.key === 'Escape') {
          handleCancel();
        }
        if (onKeyUp) {
          onKeyUp(e);
        }
      },
      [handleRequestSearch, handleCancel],
    );

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
    }));

    return (
      <Search searchFilterOn={searchFilterOn}>
        {searchFilterOn ? (
          <>
            <ClearIconWrapper onClick={handleCancel}>
              <ClearIcon />
            </ClearIconWrapper>
            <SearchFilter>
              {searchFilterOn ? `${target}: ${inputValue}` : value}
            </SearchFilter>
          </>
        ) : (
          <>
            <SearchIconWrapper onClick={handleCancel}>
              <SearchIcon />
            </SearchIconWrapper>
            <Input
              variant="standard"
              disabled={searchFilterOn}
              inputRef={inputRef}
              onBlur={handleBlur}
              onChange={handleInput}
              onFocus={handleFocus}
              onKeyUp={handleKeyUp}
              placeholder={placeholder}
              value={searchFilterOn ? `${target} : ${inputValue}` : value}
              error={value.length <= 1 && touched}
              helperText={
                value.length <= 1 &&
                touched &&
                '최소 두 글자 이상 입력해주세요.'
              }
            ></Input>
          </>
        )}
      </Search>
    );
  },
);

export default SearchBar;
