import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const dayformatter = (
  date?: string | number | Date | dayjs.Dayjs | null | undefined,
  template?: string,
  options?: { locale?: string; isZuluTime?: boolean },
) => {
  dayjs.locale(options?.locale ?? 'en');
  return dayjs(date)
    .utc(!(options?.isZuluTime ?? false))
    .tz(dayjs.tz.guess())
    .format(template);
};

export const removeWhiteSpace = (str: string) => {
  return str.replace(/ /g, '');
};

export const errorMessage = (error: unknown) => {
  let errorMessage = 'Unknown Error';
  if (error instanceof Error) {
    errorMessage = error.message || 'Unknown Error';
  } else if (typeof error === 'string') errorMessage = error;
  return errorMessage;
};

export const getTodayStr = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  let monthStr = '00';
  if (month < 10) {
    monthStr = '0' + month;
  } else {
    monthStr = month.toString();
  }
  const date = today.getDate();
  let dateStr = '00';
  if (date < 10) {
    dateStr = '0' + date;
  } else {
    dateStr = date.toString();
  }
  let todayStr = (year + '-' + monthStr + '-' + dateStr).trim();

  return todayStr;
};

export const currencySelector = (locale?: string) => {
  switch (locale) {
    case 'ko':
      return 'KRW';
    case 'en':
      return 'USD';
    default:
      return 'USD';
  }
};

export const currencyFormatter = (locale?: string, currency?: string) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || currencySelector(locale),
  });

export const firstLetterToUpper = (text: string) =>
  text.substring(0, 1).toUpperCase() + text.substring(1, text.length);

export const getRegionNameFromCode = (countryCode: string) =>
  new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode);

export const getLanguageNameFromCode = (languageCode: string) =>
  new Intl.DisplayNames(['en'], { type: 'language' }).of(languageCode);
