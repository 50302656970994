import { useCallback, useRef, useState } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Paper,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import type {
  UploadBeforeHandler,
  UploadBeforeReturn,
  UploadInfo,
} from 'suneditor-react/dist/types/upload';
import 'suneditor/dist/css/suneditor.min.css';
import { Controller, useForm } from 'react-hook-form';

import Header from '@components/organisms/Header';
import ImgItem from '@components/molecules/ImgGallery/ImgItem';
import {
  DisclosureType,
  LeagueType,
  MatchImageType,
} from '@usertypes/matchData';
import { useSnackbar } from 'notistack';
import useAuth from '@hooks/useAuth';
import {
  crawlingEvent,
  createEvent,
  updateEvent,
  uploadImages,
} from '@api/event';
import { getStudioUserUid } from '@api/user';
import Input from '@components/molecules/Input';
import Select from '@components/molecules/Select';
import { useMutation } from '@tanstack/react-query';
import Editor from '@components/organisms/Editor';
import { Divider } from '@components/atoms/Spacing';
import { SportType } from '@usertypes/scoreboardData';
import { EventEnvironmentType, EventType } from '@usertypes/event';
import PlaceField from '@components/molecules/PlaceField';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { dayformatter } from '@utils/utilFunctions';
import dayjs, { Dayjs } from 'dayjs';

type UrlFormValues = {
  url: string;
  image_enable: boolean;
};

const EventUrl = ({
  isLoading,
  submit,
}: {
  isLoading: boolean;
  submit: ({
    url,
    image_enable,
  }: {
    url: string;
    image_enable: boolean;
  }) => void;
}) => {
  const { handleSubmit, control, formState } = useForm<UrlFormValues>({
    mode: 'onChange',
    defaultValues: {
      url: '',
      image_enable: false,
    },
  });

  const onSubmit = ({
    url,
    image_enable,
  }: {
    url: string;
    image_enable: boolean;
  }) => submit({ url, image_enable });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="image_enable"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            {...field}
            name="image_enable"
            control={<Checkbox name="image_enable" />}
            label="이미지 분석"
          />
        )}
      />
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, gap: 1 }}
      >
        <Input
          control={control}
          name="url"
          rules={{ required: true }}
          textFieldProps={{
            error: Boolean(formState.errors.url),
            label: '이벤트 URL',
            placeholder: '이벤트 URL을 입력하세요.',
            disabled: isLoading,
            fullWidth: true,
            helperText: formState.errors.url ? '이벤트 URL을 입력하세요.' : '',
          }}
        />
        <Button type="submit" variant="contained" disabled={isLoading}>
          {isLoading ? '추출 중...' : '데이터 추출'}
        </Button>
      </Box>
      {isLoading && <LinearProgress sx={{ mb: 1 }} />}
    </form>
  );
};

type NicknameFormValues = {
  nickname: string;
};

const Handle = ({
  isLoading,
  submit,
}: {
  isLoading: boolean;
  submit: (nickname: string) => void;
}) => {
  const { handleSubmit, control, formState } = useForm<NicknameFormValues>({
    mode: 'onChange',
    defaultValues: {
      nickname: '',
    },
  });

  const onSubmit = ({ nickname }: { nickname: string }) => submit(nickname);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, gap: 1 }}
      >
        <Input
          control={control}
          name="nickname"
          rules={{ required: true }}
          textFieldProps={{
            label: '채널 핸들',
            placeholder: "'@'를 제외하고 입력해 주세요.",
            disabled: isLoading,
            fullWidth: true,
            error: Boolean(formState.errors.nickname),
            helperText: formState.errors.nickname
              ? '채널 핸들을 입력하세요.'
              : '',
          }}
        />
        <Button type="submit" variant="contained" disabled={isLoading}>
          채널 조회
        </Button>
      </Box>
    </form>
  );
};

const ImgGallery = ({
  imgInfoList,
  thumbnailImgIdx,
  handleThumbnailImgIdx,
}: {
  imgInfoList: (MatchImageType | null)[];
  thumbnailImgIdx: string | null;
  handleThumbnailImgIdx: (idx: string | null) => void;
}) => {
  const handleThumbnailImg = (selectedImgIdx: string | null) => {
    handleThumbnailImgIdx(selectedImgIdx);
  };

  return (
    <Box
      sx={{
        maxHeight: '300px',
        display: 'flex',
        gap: 2,
        overflowX: 'auto',
        width: '100%',
        mb: 2.5,
        flex: 1,
        '&::-webkit-scrollbar': {
          width: '0px',
          height: '2px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#6E717E',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#D1D2D7',
        },
      }}
    >
      {imgInfoList.map((imgInfo) => {
        if (imgInfo === null) return null;
        return (
          <ImgItem
            key={imgInfo.fileName}
            img={imgInfo}
            isShowThumbnailImg
            thumbnailImg={thumbnailImgIdx}
            type="event"
            updateThumbnailImg={handleThumbnailImg}
          />
        );
      })}
    </Box>
  );
};

type FormValues = {
  description: string;
  detailAddress?: string;
  disclosure: DisclosureType;
  eventEnvironment?: EventEnvironmentType;
  eventType: EventType;
  from?: string;
  leagueType?: LeagueType;
  place?: string;
  placeId?: string;
  registerEnd?: string;
  registerLink: string;
  registerStart?: string;
  sportType?: SportType;
  targetUid: string;
  title: string;
  to?: string;
};

const CreateEvent = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { user, token } = useAuth();

  const [imgInfoList, setImgInfoList] = useState<(MatchImageType | null)[]>([]);
  const [thumbnailImgIdx, setThumbnailImgIdx] = useState<string | null>(null);
  const [description, setDescription] = useState<string>('');
  const [uid, setUid] = useState<string>();
  const [eventDateRange, setEventDateRange] = useState<DateRange<Dayjs>>([
    dayjs(new Date()),
    dayjs(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)),
  ]);
  const [receptionDateRange, setReceptionDateRange] = useState<
    DateRange<Dayjs>
  >([null, null]);

  const imgFileRef = useRef<{ [index: string]: File }>({});

  const sportTypeList: { value: SportType; content: string }[] = [
    { value: 'universal', content: '공용' },
    { value: 'baseball', content: '야구' },
    { value: 'basketball', content: '농구' },
    { value: 'soccer', content: '축구' },
    { value: 'tennis', content: '테니스' },
    { value: 'futsal', content: '풋살' },
    { value: 'tableTennis', content: '탁구' },
    { value: 'volleyball', content: '배구' },
    { value: 'badminton', content: '배드민턴' },
    { value: 'hockey', content: '하키' },
    { value: 'iceHockey', content: '아이스 하키' },
    { value: 'cricket', content: '크리켓' },
    { value: 'billiard', content: '당구' },
    { value: 'handball', content: '핸드볼' },
    { value: 'jokgu', content: '족구' },
    { value: 'golf', content: '골프' },
    { value: 'squash', content: '스쿼시' },
    { value: 'football', content: '미식축구' },
    { value: 'sepakTakraw', content: '세팍타크로' },
    { value: 'athletics', content: '육상' },
    { value: 'esports', content: 'e-스포츠' },
    { value: 'bodybuilding', content: '보디빌딩' },
    { value: 'climbing', content: '클라이밍' },
  ];

  const leagueTypeList: { value: LeagueType; content: string }[] = [
    { value: 'child', content: '유아부' },
    { value: 'elementarySchool', content: '초등부' },
    { value: 'middleSchool', content: '중등부' },
    { value: 'highSchool', content: '고등부' },
    { value: 'university', content: '대학부' },
    { value: 'club', content: '동호회 / 동아리' },
    { value: 'amateur', content: '아마추어' },
    { value: 'pro', content: '프로' },
  ];

  const eventEnvironmentList = [
    { value: 'online', content: '온라인' },
    { value: 'offline', content: '오프라인' },
    {
      value: 'on/offline',
      content: '온/오프라인',
    },
  ];

  const handleImageUploadBefore = (
    files: File[],
    _info: object | { element: HTMLElement; isUpdate: boolean },
    uploadHandler: UploadBeforeHandler,
  ): UploadBeforeReturn => {
    const imgUploadFail = (msg: string) => {
      const key = enqueueSnackbar(msg, {
        variant: 'error',
        onClick: () => closeSnackbar(key),
      });
      uploadHandler();
      return false;
    };

    const targetFile = files[0];

    if (user === null || !user.id)
      return imgUploadFail('사용자 정보를 찾을 수 없습니다.');

    if (!targetFile || !targetFile.type.match('image.*'))
      return imgUploadFail('이 파일은 이미지 형식이 아닙니다.');

    const tmpUrl = URL.createObjectURL(targetFile);
    imgFileRef.current[tmpUrl] = targetFile;

    uploadHandler({
      result: [
        {
          url: tmpUrl,
          name: targetFile.name,
          size: targetFile.size,
        },
      ],
    });
  };

  const handleImageUpload = (
    _targetImgElement: HTMLImageElement,
    index: number,
    state: 'create' | 'update' | 'delete',
    imageInfo: UploadInfo<HTMLImageElement>,
  ) => {
    setImgInfoList((prev) => {
      const tmp = [...prev];

      switch (state) {
        case 'create':
          if (tmp.length === 0) setThumbnailImgIdx(`${imageInfo.index}`);
          tmp[imageInfo.index] = {
            fileName: `${imageInfo.index}`,
            url: imageInfo.src,
          };
          break;
        case 'delete':
          tmp.splice(index, 1, null);
          if (thumbnailImgIdx === `${index}`) setThumbnailImgIdx(null);
          break;
        case 'update':
          tmp.splice(index, 1, {
            fileName: `${imageInfo.index}`,
            url: imageInfo.src,
          });
          break;
      }

      return tmp;
    });
  };

  const eventTypeList = [
    { value: 'universal', content: '일반' },
    { value: 'sport', content: '스포츠' },
    { value: 'seminar', content: '세미나' },
  ];

  const disclosureList = [
    { value: 'public', content: '공개' },
    { value: 'unlisted', content: '일부 공개' },
    { value: 'private', content: '비공개' },
  ];

  const getImageHtml = (imgUrl: string) => {
    return `<div class="se-component se-image-container __se__float-none"><figure><img src=${imgUrl}></figure></div>`;
  };

  const getEventDataMutate = useMutation(
    ({ url, image_enable }: { url: string; image_enable: boolean }) =>
      crawlingEvent(url, image_enable),
    {
      retry: 0,
      onSuccess: (result) => {
        let description = result.description;

        if (result?.imageList && result.imageList.length > 0) {
          description += result.imageList.map(getImageHtml).join('');

          const imageList = [...new Set(result.imageList)];
          setImgInfoList(
            imageList.map((imgUrl) => ({
              fileName: imgUrl,
              url: imgUrl,
            })),
          );
        }

        const wrappedText = description
          .split('\n')
          .map((line) => `<p>${line}</p>`)
          .join('');

        if (result.registerStart && result.registerEnd) {
          setReceptionDateRange([
            dayjs(result.registerStart, 'YY-MM-DD'),
            dayjs(result.registerEnd, 'YY-MM-DD'),
          ]);
        }

        if (result.eventStart && result.eventEnd) {
          setEventDateRange([
            dayjs(result.eventStart, 'YY-MM-DD'),
            dayjs(result.eventEnd, 'YY-MM-DD'),
          ]);
        }

        setDescription(wrappedText);
      },
      onError: (error) => {
        if (
          error instanceof Error &&
          error?.message === 'Request failed with status code 404'
        ) {
          const key = enqueueSnackbar(
            '접근이 불가한 웹사이트입니다. 다른 웹사이트를 입력해 주세요.',
            {
              variant: 'error',
              onClick: () => closeSnackbar(key),
            },
          );
        }
      },
    },
  );

  const getUidMutate = useMutation(
    (nickname: string) => getStudioUserUid(token, nickname),
    {
      onSuccess: (uid) => {
        setUid(uid);
        const key = enqueueSnackbar('존재하는 채널입니다.', {
          variant: 'success',
          onClick: () => closeSnackbar(key),
        });
      },
    },
  );

  const setRegisterLink = (url: string) =>
    url.includes('정보 없음') ? '' : url;

  const { control, handleSubmit, watch, setValue, formState } =
    useForm<FormValues>({
      mode: 'onChange',
      values: {
        title: getEventDataMutate.data?.title || '',
        eventType: 'sport',
        registerLink: setRegisterLink(
          getEventDataMutate.data?.registerInfo || '',
        ),
        description: '',
        targetUid: uid || '',
        disclosure: 'public',
        sportType: 'universal',
        leagueType: 'club',
        eventEnvironment: 'online',
        detailAddress: '',
      },
      resetOptions: {
        keepDirtyValues: true,
      },
    });

  const createEventMutate = useMutation(
    (data: FormValues) => createEvent(token, data),
    {
      onSuccess: (result) => {
        if (uid) {
          const imgUrlList = imgInfoList
            .filter((img) => img !== null)
            .map((img) => img!.url);

          uploadImagesMutate.mutate({
            images: imgUrlList,
            uid: uid,
            leaderBoardId: result.createLeaderboardResult.id,
          });
        }
      },
    },
  );

  const uploadImagesMutate = useMutation(
    ({
      images,
      uid,
      leaderBoardId,
    }: {
      images: string[];
      uid: string;
      leaderBoardId: number;
    }) => uploadImages({ images, uid, leaderBoardId }),
    {
      onSuccess: (result, { images }) => {
        const img_url_list = result.data.img_url_list;

        const imageInfo = images.map((imgUrl, index) => ({
          firebaseImgUrl: img_url_list[index],
          prevImgUrl: imgUrl,
        }));

        updateEventMutate.mutate({ images: imageInfo });
      },
    },
  );
  const storage = getStorage();

  const updateEventMutate = useMutation(
    async ({
      images,
    }: {
      images: {
        prevImgUrl: string;
        firebaseImgUrl: string;
      }[];
    }) => {
      const leaderboardId = createEventMutate.data?.createLeaderboardResult.id;
      let description = '';
      let thumbnailUrl = null;
      let thumbnailName = null;

      if (createEventMutate.data) {
        description =
          createEventMutate.data.createLeaderboardResult.description;

        const imagePromises = Object.entries(images).map(
          async ([, imgInfo], index) => {
            try {
              const leaderboardImageRef = ref(storage, imgInfo.firebaseImgUrl);
              const imgUrl = await getDownloadURL(leaderboardImageRef);

              if (thumbnailImgIdx === `${index}`) {
                thumbnailUrl = imgUrl;
                thumbnailName = imgInfo.firebaseImgUrl;
              }

              description = description.replace(imgInfo.prevImgUrl, imgUrl);
            } catch (e) {
              console.log('🚀 ~ CreateEvent ~ e:', e);
            }
          },
        );

        await Promise.all(imagePromises);

        const eventUpdatePayload = {
          description,
          ...(thumbnailUrl !== null && thumbnailName !== null
            ? { thumbnailUrl, thumbnailName }
            : {}),
        };

        return updateEvent(
          token,
          leaderboardId || 0,
          eventUpdatePayload,
          uid || '0',
        );
      }
    },
    {
      onSuccess: () => {
        const key = enqueueSnackbar('이벤트가 생성되었습니다.', {
          variant: 'success',
          onClick: () => closeSnackbar(key),
        });
      },
    },
  );

  const handleBlurTextEditor = (_e: FocusEvent, editorContents: string) => {
    setDescription(editorContents);
  };

  const isRegisterDisabled =
    createEventMutate.isLoading ||
    getEventDataMutate.isLoading ||
    uploadImagesMutate.isLoading;

  const onSubmit = (data: FormValues) => {
    if (!uid) return;

    try {
      const updateFormFields: {
        [key in EventType]: (keyof FormValues)[];
      } = {
        universal: ['leagueType', 'eventEnvironment', 'sportType'],
        sport: ['eventEnvironment'],
        seminar: ['sportType', 'leagueType'],
      };

      let updatedData = {
        ...data,
        description: description,
        from: eventDateRange[0]?.toISOString(),
        to: eventDateRange[1]?.toISOString(),
        registerStart: receptionDateRange[0]?.toISOString(),
        registerEnd: receptionDateRange[1]?.toISOString(),
      };

      updateFormFields[data.eventType].forEach((field) => {
        delete updatedData[field];
      });

      return createEventMutate.mutate(updatedData);
    } catch (e) {
      console.error(e);
    }
  };

  const setFormPlaceData = useCallback(
    ({
      place,
      placeId,
      detailAddress,
    }: {
      place?: string;
      placeId?: string;
      detailAddress?: string;
    }) => {
      if (place) setValue('place', place);
      if (placeId) setValue('placeId', placeId);
      if (detailAddress) setValue('detailAddress', detailAddress);
    },
    [setValue],
  );

  return (
    <Box>
      <Header title="Create Event" subTitle="CameraFi Studio / Events" />
      <Paper sx={{ p: 4 }}>
        <Handle
          isLoading={getUidMutate.isLoading}
          submit={getUidMutate.mutate}
        />
        <Divider my={2} />
        <EventUrl
          isLoading={getEventDataMutate.isLoading}
          submit={getEventDataMutate.mutate}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Input
              control={control}
              name="title"
              rules={{ required: true }}
              textFieldProps={{
                label: '제목',
                placeholder: '이벤트 제목을 입력하세요.',
                error: Boolean(formState.errors.title),
                helperText: formState.errors.title
                  ? '이벤트 제목을 입력하세요.'
                  : '',
              }}
            />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Select
                  control={control}
                  selectProps={{ label: '이벤트 타입' }}
                  name="eventType"
                  rules={{ required: true }}
                >
                  {eventTypeList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.content}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {watch('eventType') === 'sport' && (
                <>
                  <Grid item xs={4}>
                    <Select
                      control={control}
                      selectProps={{ label: '종목' }}
                      name="sportType"
                    >
                      {sportTypeList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.content}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      control={control}
                      selectProps={{ label: '선수 구분' }}
                      name="leagueType"
                    >
                      {leagueTypeList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.content}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </>
              )}
              {watch('eventType') === 'seminar' && (
                <>
                  <Grid item xs={4}>
                    <Select
                      control={control}
                      selectProps={{ label: '이벤트 환경' }}
                      name="eventEnvironment"
                    >
                      {eventEnvironmentList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.content}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <PlaceField
                  setFormPlaceData={setFormPlaceData}
                  defaultAddress={getEventDataMutate.data?.address}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  control={control}
                  name="detailAddress"
                  textFieldProps={{
                    label: '세부 주소',
                    placeholder: '세부 주소를 입력하세요.',
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    value={eventDateRange}
                    onChange={(newValue) => {
                      setEventDateRange(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                      <TextField
                        label="이벤트 기간"
                        sx={{
                          width: '100%',
                        }}
                        required
                        inputProps={{
                          ...startProps.inputProps,
                          value: `${dayformatter(
                            startProps.inputProps?.value,
                            'YY-MM-DD',
                          )} ~ ${dayformatter(
                            endProps.inputProps?.value,
                            'YY-MM-DD',
                          )}`,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    value={receptionDateRange}
                    onChange={(newValue) => {
                      setReceptionDateRange(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                      <TextField
                        label="접수 기간"
                        sx={{
                          width: '100%',
                        }}
                        inputProps={{
                          ...startProps.inputProps,
                          value:
                            startProps.inputProps?.value &&
                            endProps.inputProps?.value
                              ? `${dayformatter(
                                  startProps.inputProps?.value,
                                  'YY-MM-DD',
                                )} ~ ${dayformatter(
                                  endProps.inputProps?.value,
                                  'YY-MM-DD',
                                )}`
                              : '',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Input
              control={control}
              name="registerLink"
              textFieldProps={{
                label: '접수처',
                placeholder: '접수처 URL 또는 접수처 정보를 입력하세요.',
              }}
            />
            <Editor
              handleBlurEditor={handleBlurTextEditor}
              handleImageUploadBefore={handleImageUploadBefore}
              handleImageUpload={handleImageUpload}
              value={description}
            />
            <ImgGallery
              imgInfoList={imgInfoList}
              thumbnailImgIdx={thumbnailImgIdx}
              handleThumbnailImgIdx={(idx: string | null) => {
                setThumbnailImgIdx((prev) => {
                  if (prev === idx) return null;
                  else return idx;
                });
              }}
            />
            <Box
              sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}
            >
              <Box sx={{ width: '200px' }}>
                <Select
                  control={control}
                  selectProps={{ label: '공개 설정' }}
                  name="disclosure"
                  rules={{ required: true }}
                >
                  {disclosureList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.content}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Tooltip title={uid ? '' : '채널을 조회해주세요.'}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isRegisterDisabled}
                  sx={{ width: '100px' }}
                >
                  등록
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default CreateEvent;
