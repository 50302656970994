import { createContext, ReactNode, useMemo, useState } from 'react';
import { Dayjs } from 'dayjs';

import {
  EventListParams,
  ShoppingEventQueryContextType,
} from '@usertypes/shopify';

const ShoppingEventQueryContext =
  createContext<ShoppingEventQueryContextType | null>(null);

const ShoppingEventQueryProvider = ({ children }: { children: ReactNode }) => {
  const [status, setStatus] = useState<EventListParams['statusFilter'] | 'all'>(
    'all',
  );
  const [type, setType] = useState<EventListParams['typeFilter'] | 'all'>(
    'all',
  );
  const [sortType, setSortType] =
    useState<EventListParams['sortType']>('viewers');
  const [eventTitleFilter, setEventTitleFilter] = useState<string>('');
  const [shopNameFilter, setShopNameFilter] = useState<string>('');
  const [referenceNumberFilter, setReferenceNumberFilter] =
    useState<string>('');
  const [order, setOrder] = useState<EventListParams['order']>('DESC');
  const [country, setCountry] =
    useState<ShoppingEventQueryContextType['country']>(null);
  const [startTimeFilterStart, setStartTimeFilterStart] =
    useState<Dayjs | null>(null);
  const [startTimeFilterEnd, setStartTimeFilterEnd] = useState<Dayjs | null>(
    null,
  );
  const [endTimeFilterStart, setEndTimeFilterStart] = useState<Dayjs | null>(
    null,
  );
  const [endTimeFilterEnd, setEndTimeFilterEnd] = useState<Dayjs | null>(null);

  const userListInfos = useMemo(
    () => ({
      country,
      endTimeFilterEnd,
      endTimeFilterStart,
      eventTitleFilter,
      order,
      referenceNumberFilter,
      setCountry,
      setEndTimeFilterEnd,
      setEndTimeFilterStart,
      setEventTitleFilter,
      setOrder,
      setReferenceNumberFilter,
      setShopNameFilter,
      setSortType,
      setStartTimeFilterEnd,
      setStartTimeFilterStart,
      setStatus,
      setType,
      shopNameFilter,
      sortType,
      startTimeFilterEnd,
      startTimeFilterStart,
      status,
      type,
    }),
    [
      country,
      endTimeFilterEnd,
      endTimeFilterStart,
      eventTitleFilter,
      order,
      referenceNumberFilter,
      shopNameFilter,
      sortType,
      startTimeFilterEnd,
      startTimeFilterStart,
      status,
      type,
    ],
  );

  return (
    <ShoppingEventQueryContext.Provider
      value={{
        ...userListInfos,
      }}
    >
      {children}
    </ShoppingEventQueryContext.Provider>
  );
};

export { ShoppingEventQueryContext, ShoppingEventQueryProvider };
