import { Grid } from '@mui/material';

import { RowDetailsItem } from './RowDetails.style';
import { DetailsProps } from './RowDetails.type';

const RowDetails = ({ defaultDetailItem, dense, itemList }: DetailsProps) => (
  <>
    {itemList.map((item, index) => (
      <RowDetailsItem
        dense={dense.toString()}
        container
        key={index}
        columnSpacing={4}
      >
        {Object.keys(item)[0] === 'rowDetailSubtitle' ? (
          <Grid item>
            <h3>{Object.values(item)[0]}</h3>
          </Grid>
        ) : (
          <>
            <Grid item xs={3}>
              {Object.keys(item)[0]}
            </Grid>
            <Grid
              item
              xs={9}
              sx={{ whiteSpace: 'pre-wrap', wordBreak: 'keep-all' }}
            >
              {Object.values(item)[0]}
            </Grid>
          </>
        )}
      </RowDetailsItem>
    ))}
    {defaultDetailItem ? defaultDetailItem(dense.toString()) : null}
  </>
);

export default RowDetails;
