import { SvgIcon, SvgIconProps } from '@mui/material';

const Youtube = (props: SvgIconProps) => (
  <SvgIcon sx={{ height: 36, width: 36 }} viewBox="0 0 32 32" {...props}>
    <path
      d="M28.784 9.647a3.423 3.423 0 0 0-.857-1.531 3.324 3.324 0 0 0-1.493-.89C24.347 6.667 16 6.667 16 6.667s-8.347 0-10.434.56a3.325 3.325 0 0 0-1.493.89 3.423 3.423 0 0 0-.857 1.53c-.55 2.13-.55 6.544-.55 6.544s0 4.437.55 6.565c.154.577.455 1.102.871 1.523.417.421.934.723 1.501.875 2.087.56 10.412.56 10.412.56s8.347 0 10.434-.56a3.324 3.324 0 0 0 1.488-.88c.413-.42.71-.943.862-1.517.55-2.13.55-6.544.55-6.544s0-4.436-.55-6.543"
      fill="red"
    />
    <path d="m13.342 20.268 6.94-4.078-6.94-4.078v8.156Z" fill="#fff" />
  </SvgIcon>
);

export default Youtube;
