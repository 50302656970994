import { Box } from '@mui/material';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  styled,
} from '@mui/material';

export const Card = styled(MuiCard)`
  border-radius: 0%;
  box-shadow: none;
  transition: none;
`;

export const Overlay = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLive',
})<{ isLive?: boolean }>(({ isLive }) => ({
  backgroundColor: isLive ? '#cc0000e6' : 'rgba(0, 0, 0, 0.8)',
  borderRadius: '2px',
  bottom: '0px',
  color: 'white',
  display: isLive ? 'flex' : undefined,
  justifyContent: isLive ? 'space-evenly' : undefined,
  margin: '4px',
  padding: isLive ? '1px 4px' : '3px',
  position: 'absolute',
  right: '0px',
  verticalAlign: 'middle',
  width: isLive ? '60px' : undefined,

  svg: {
    fill: 'white',
  },

  span: {
    fontSize: isLive ? '14px' : undefined,
    fontWeight: 500,
    lineHeight: isLive ? '1em' : '12px',
  },
}));

export const CardContent = styled(MuiCardContent)`
  background-color: ${({ theme }) => theme.palette.background.default};
  height: 126px;
  margin: 0px;
  padding: 0px;
`;

export const AvatarArea = styled('div')`
  height: 36px;
  margin-right: 12px;
  width: 36px;
`;

export const TitleWrapper = styled('div')`
  padding-top: 12px;
  display: flex;
`;

export const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: auto;
`;

export const Title = styled(Typography)`
  cursor: pointer;
  display: -webkit-box;
  font-weight: 600;
  line-height: 1.5em;
  max-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  :hover {
    text-decoration: underline;
  }
`;

export const SubTitle = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
`;
