import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import { List, styled, useMediaQuery, useTheme } from '@mui/material';

import { SidebarItemsType } from '@usertypes/sidebar';
import '@styles/perfect-scrollbar.css';

import SidebarNavSection from './SidebarNavSection';

const Scrollbar = styled('div')({
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  flexGrow: 1,
});

const PerfectScrollbar = styled(ReactPerfectScrollbar)({
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  flexGrow: 1,
});

const Items = styled('div')`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const SidebarNav: React.FC<{
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  pathname: string;
}> = ({ items, pathname }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages}
                title={item.title}
                pathname={pathname}
              />
            ))}
        </Items>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
