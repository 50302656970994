import { Typography, styled } from '@mui/material';

import { SidebarItemsType } from '@usertypes/sidebar';

import SidebarNavList from './SidebarNavList';

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.bold};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  padding: ${({ theme }) =>
    `${theme.spacing(4)} ${theme.spacing(7)} ${theme.spacing(1)}`};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

const SidebarNavSection: React.FC<{
  className?: Element;
  component?: React.ElementType;
  pages: SidebarItemsType[];
  pathname: string;
  title?: string;
}> = ({
  title,
  pages,
  className,
  pathname,
  component: Component = 'nav',
  ...rest
}) => (
  <Component {...rest}>
    {title && <Title variant="subtitle2">{title}</Title>}
    <SidebarNavList pages={pages} depth={0} pathname={pathname} />
  </Component>
);

export default SidebarNavSection;
