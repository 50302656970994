import { fetchLiveJSON } from '@utils/api-helpers';

export const signUpRequest = (
  token: string,
  id: string,
  email: string,
  username: string,
  profileUrl: string,
) =>
  fetchLiveJSON('POST', token, '/api/v1/users', {
    id,
    email,
    username,
    profileUrl,
  });
