import { LeaderboardTeamData } from '@usertypes/event';
import { fetchStudioAxios } from '@utils/api-helpers';
import axios from 'axios';

const TITLE = '1';
const EVENT_PERIOD = '2';
const ADDRESS = '3';
const REGISTER_PERIOD = '4';
const REGISTER_INFO = '5';
const DESCRIPTION = '6';

interface EventDetails {
  [TITLE]: string;
  [EVENT_PERIOD]: string;
  [ADDRESS]: string;
  [REGISTER_PERIOD]: string;
  [REGISTER_INFO]: string;
  [DESCRIPTION]: string;
}

interface Event {
  json_result: EventDetails;
  image_list?: string[];
}

interface EventPostData {
  title: string;
  eventType: string;
  registerLink: string;
  description: string;
}

interface EventUpdateData {
  description: string;
  thumbnailName?: string;
  thumbnailUrl?: string;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CRAWLING_SERVICE_URL,
});

const crawlingEvent = async (url: string, image_enable: boolean) => {
  const queryParams = new URLSearchParams({
    url: url,
    image_enable: `${image_enable}`,
  }).toString();

  const result = await axiosInstance.get<Event>(
    `crawling-service-api/url?${queryParams}`,
  );

  return {
    title: result.data.json_result[TITLE],
    eventStart: result.data.json_result[EVENT_PERIOD]?.split(' ~ ')[0],
    eventEnd: result.data.json_result[EVENT_PERIOD]?.split(' ~ ')[1],
    address: result.data.json_result[ADDRESS],
    registerStart: result.data.json_result[REGISTER_PERIOD]?.split(' ~ ')[0],
    registerEnd: result.data.json_result[REGISTER_PERIOD]?.split(' ~ ')[1],
    registerInfo: result.data.json_result[REGISTER_INFO],
    description: result.data.json_result[DESCRIPTION],
    imageList: result.data.image_list,
  };
};

const createEvent = async (
  token: string,
  data: EventPostData,
): Promise<{
  createLeaderboardResult: {
    id: number;
    description: string;
  };
  createTeamResult: LeaderboardTeamData[];
}> =>
  await fetchStudioAxios(
    'POST',
    token,
    '/studio/api/v1/leaderboard/admin',
    data,
  );

const updateEvent = async (
  token: string,
  leaderBoardId: number,
  data: EventUpdateData,
  targetUid: string,
) => {
  const result = await fetchStudioAxios(
    'PATCH',
    token,
    `/studio/api/v1/leaderboard/admin/${leaderBoardId}`,
    {
      updateLeaderboardInfo: data,
      targetUid,
    },
  );

  return result;
};

const uploadImages = async ({
  images,
  uid,
  leaderBoardId,
}: {
  images: string[];
  uid: string;
  leaderBoardId: number;
}) => {
  const result = await axiosInstance.post<{
    result: string;
    img_url_list: string[];
  }>('upload_leaderboard_img', {
    images,
    uid,
    leaderboard_id: leaderBoardId,
  });

  return result;
};

export { crawlingEvent, createEvent, uploadImages, updateEvent };
