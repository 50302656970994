import { styled } from '@mui/material';

export const Img = styled('img')<{
  isMouseEnter: boolean;
}>`
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;

  filter: ${({ isMouseEnter }) =>
    isMouseEnter ? 'brightness(80%)' : 'brightness(100%)'};
  transition: filter 0.5s;
`;
