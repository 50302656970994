import { Helmet } from 'react-helmet-async';
import ResetPasswordComponent from '@components/organisms/ResetPassword';

const ResetPassword = () => (
  <>
    <Helmet title="Reset Password" />
    <ResetPasswordComponent />
  </>
);

export default ResetPassword;
