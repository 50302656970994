import { matchPath } from 'react-router-dom';

import { SidebarItemsType } from '@usertypes/sidebar';

import SidebarNavListItem from './SidebarNavListItem';
import SidebarNavList from './SidebarNavList';

const reduceChildRoutes = ({
  depth,
  items,
  page,
  pathname,
}: {
  depth: number;
  items: JSX.Element[];
  page: SidebarItemsType;
  pathname: string;
}) => {
  const currentRoute = `/${pathname.split('/')[1]}`;
  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute,
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        open={!!open}
        pathname={pathname}
        title={page.title}
      >
        <SidebarNavList
          depth={depth + 1}
          pages={page.children}
          pathname={pathname}
        />
      </SidebarNavListItem>,
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        pathname={pathname}
        title={page.title}
      />,
    );
  }

  return items;
};

export default reduceChildRoutes;
