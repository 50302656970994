import { TextField, TextFieldProps } from '@mui/material';
import {
  useController,
  FieldValues,
  FieldPath,
  UseControllerProps,
} from 'react-hook-form';

interface MuiProps {
  textFieldProps?: TextFieldProps;
}

const Input = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  textFieldProps,
  ...props
}: MuiProps & UseControllerProps<TFieldValues, TName>) => {
  const { field } = useController(props);

  return <TextField fullWidth {...field} {...textFieldProps} />;
};

export default Input;
