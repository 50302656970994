import { forwardRef, useState } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import {
  Collapse,
  ListItemProps,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const Item = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'depth',
})<{
  isActive?: boolean | undefined;
  activeclassname?: string | undefined;
  component?: typeof NavLink;
  depth: number;
  to?: string;
  onClick?: (() => void) | undefined;
}>(({ isActive, depth, theme }) => ({
  cursor: 'pointer',
  paddingTop: theme.spacing(depth > 0 ? 2 : 3),
  paddingBottom: theme.spacing(depth > 0 ? 2 : 3),
  paddingLeft: theme.spacing(depth > 0 ? 12 : 8),
  paddingRight: theme.spacing(depth > 0 ? 4 : 7),
  fontWeight: theme.typography.fontWeightRegular,
  svg: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    width: '20px',
    height: '20px',
    opacity: 0.5,
  },
  span: {
    color: isActive ? theme.palette.text.bold : undefined,
    fontWeight: isActive ? 600 : undefined,
  },
  '&:hover': {
    color: theme.palette.text.bold,
    svg: {
      color: theme.palette.text.bold,
    },
  },
}));

const Title = styled(ListItemText)`
  margin: 0;
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(1)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  fill: ${({ theme }) => theme.palette.text.bold};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  fill: ${({ theme }) => theme.palette.text.bold};
`;

const SidebarNavListItem: React.FC<
  ListItemProps & {
    className?: string;
    depth: number;
    href: string;
    icon: React.FC<any>;
    open?: boolean;
    title: string;
    pathname: string;
  }
> = ({
  title,
  href,
  depth = 0,
  children,
  icon: Icon,
  pathname,
  open: openProp = false,
}) => {
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <>
        <Item depth={depth} onClick={handleToggle}>
          {Icon && <Icon />}
          <Title>{title}</Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </>
    );
  }

  return (
    <>
      <Item
        isActive={pathname.startsWith(href)}
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
      >
        {Icon && <Icon />}
        <Title>{title}</Title>
      </Item>
    </>
  );
};

export default SidebarNavListItem;
