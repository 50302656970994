import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';

import { TableListProps } from './TableList.type';

const TableList = ({ detail }: TableListProps) => {
  const columns = [
    'Title',
    'Platform',
    'UserName',
    'Visibility',
    'Time',
    'Top View',
    'Avg View',
    'Resolution',
    'Bitrate',
    'Information',
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '350px' }}>
        <TableBody>
          {Object.keys(detail).map((key, index) => (
            <TableRow key={index}>
              <TableCell>{columns[index]}</TableCell>
              <TableCell component="th">
                {detail[key as keyof typeof detail] || <del>EMPTY DATA</del>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableList;
