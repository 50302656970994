import { MouseEventHandler } from 'react';
import { Grid, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import { AppBar, IconButton } from './Navbar.style';

const Navbar: React.FC<{
  onDrawerToggle: MouseEventHandler<HTMLElement>;
}> = ({ onDrawerToggle }) => (
  <AppBar position="sticky" elevation={0}>
    <Toolbar sx={{ display: { xs: 'block', md: 'none' } }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item>
          <IconButton
            aria-label="Open drawer"
            color="inherit"
            onClick={onDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

export default Navbar;
